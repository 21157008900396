
// react
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import asset2364 from "./img/2364.png"
import asset2363 from "./img/2363.png"
import asset2362 from "./img/2362.png"
import asset2411 from "./img/2411.png"
import MCO from "./img/MCO.png"
import Approved from "./img/Approved (2).png"
import BlackSpots from "./img/Black Spots.png"
import CIApproved from "./img/CI Approved.png"
import CIDone from "./img/CI Done.png"
import CIPending from "./img/CI Pending.png"
import CIRejected from "./img/CI Rejected.png"
import Fieldinstalled from "./img/Field installed.png"
import intalledMeter from "./img/intalled Meter.png"
import Metercummunicationanalysis from "./img/Meter cummunication analysis.png"
import MIApproved from "./img/MI Approved.png"
import MIDate from "./img/MI Date.png"
import MIDone from "./img/MI Done.png"
import MIPending from "./img/MI Pending.png"
import MIRejected from "./img/MI Rejected.png"
import MSOClosed from "./img/MSO Closed.png"
import MSOIssued from "./img/MSO Issued.png"
import MSOWorkInProgress from "./img/MSO Work In Progress.png"
import NonCommunicatingMeter from "./img/Non Communicating Meter (2).png"
import ProvisionedHESMDM from "./img/Provisioned HES MDM.png"
import Readfails from "./img/Read fails.png"
import Rejected from "./img/Rejected.png"
import Servicerequesttype from "./img/Service request type.png"
import Specificalarm from "./img/Specific alarm.png"
import Specificdefect from "./img/Specific defect.png"
import BLACKSPOT from "./img/BLACKSPOT.jpeg"
import CIUNAPPROVED from "./img/CI UNAPPROVED.png"
import MIUNAPPROVED from "./img/MI UNAPPROVED.png"
import NONCOMMUNICATINGPREMISES from "./img/NON COMMUNICATING PREMISES.png"
import OLDMETERRETURNED from "./img/OLD METER RETURNED.jpeg"
import PENDINGMSO from "./img/PENDING MSO.jpeg"
import * as turf from '@turf/turf'
import ConfigData from "./ConfigData.json";
import "./vendor/fontawesome-free/css/all.min.css";
import "./vendor/bootstrap.min.css";
import "./css/ruang-admin.min.css";
import "./css/Style.css";
// openlayers
import Map from 'ol/Map'
import View from 'ol/View'
import OSM from 'ol/source/OSM'
import scaleLine from 'ol/control/ScaleLine'
import fullScreen from 'ol/control/FullScreen'
import TileLayer from 'ol/layer/Tile'
import Bingmaps from 'ol/source/BingMaps'
import { createStringXY } from 'ol/coordinate'
import { unByKey } from 'ol/Observable'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import Select from 'ol/interaction/Select'
import Modify from 'ol/interaction/Modify'
import Snap from 'ol/interaction/Snap'
import Polygon from 'ol/geom/Polygon'
import LineString from 'ol/geom/LineString'
import Point from 'ol/geom/Point'
import Draw, { createBox, } from 'ol/interaction/Draw'
import Overlay from 'ol/Overlay'
import XYZ from 'ol/source/XYZ'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML';
import WKT from 'ol/format/WKT';
import { Cluster } from 'ol/source';
import { fetchImage, httpPost } from './api/AxiosHelper';
import { clearLocalStorage } from './util/local-storage-util';
import DataTables from 'datatables.net-dt';
import $ from 'jquery'
import shpwrt from 'shp-write';
import { saveAs } from 'file-saver'
import jszip from 'jszip'
import { utils, writeFile } from 'xlsx'

function App(props) {
    // set intial state
    const [mapforArrowFunction, setMap] = useState()
    const [scalecontrolforArrowFunction, setScaleControl] = useState()
    const [assetsSourceforArrowFunction, setassetsSorce] = useState()
    const [spatialQuerySourceOut, setSpatialQuerySOurce] = useState()
    const [connectivitySourceforArrowFunction, setconnectivitySorce] = useState()
    const [connectivityLayerforArrowFunction, setconnectivityLayer] = useState()
    const [drawPolgonSourceArrowfunction, setDrawpolygonSource] = useState()
    const [printTextSourceforArrowFunction, setPrintTextSorce] = useState()
    const [drawinteractionArrowFunction, setDrawINteraction] = useState()
    const [selectedFeatureArrowFunction, setSelectedFeature] = useState()
    const [selectedFeatureDistributionNodeID, setDistributionNodeID] = useState()
    const [drawMeasureOut, setDrawMeasure] = useState()
    const [rubberbandZoomInteractionOut, setRubberbandZoom] = useState()
    const [outSource, setsource] = useState()
    const [featuresLayer, setFeaturesLayer] = useState()
    const [selectedCoord, setSelectedCoord] = useState()
    const [bingImageOut, setBingImage] = useState()
    const [bingMapOut, setBingMap] = useState()
    const [bingHybridOut, setBingHybrid] = useState()
    const [osmOut, setOSM] = useState()
    const [googleMapTerainOut, setGoogleTerrain] = useState()
    const [usgsTopoOut, setusgsTopo] = useState()
    const [noImageOut, setnoImage] = useState()
    const [locations, setlocations] = useState([])
    const [locationsinDrawPolygon, setlocationsinDrawPolygon] = useState([])
    const [PropertyTextsData, setPropertyTextData] = useState([])
    const [POIsData, setPOIdata] = useState([])
    const [text, setText] = useState('')
    const [POItext, setPOIText] = useState('')
    const [PropertyText, setPropertyText] = useState('')
    const [suggestions, setSuggestions] = useState()
    const [selectedFile, setFile] = useState()
    const [tableData, setTableData] = useState()
    let [locationIDinAdavcendFIlters, setlocationIDinAdavcendFIlters] = useState(0)
    const tableRef = useRef(null);
    const markerTypesArray = [];
    const assettypesArray = [];
    let map = null;
    const location = useLocation();
    const dims = {
        a0: [1189, 841],
        a1: [841, 594],
        a2: [594, 420],
        a3: [420, 297],
        a4: [297, 210],
        a5: [210, 148],
    };
    let propertyIDinFilters = 0;
    var advancedFiltersType = "''";
    var p_valueidGLobal = 0;
    var parentAssetID = 0;
    var drawinteraction = null;
    var drawPolgonSource = null;
    const api = process.env.REACT_APP_PROD_API

    useEffect(() => {
        console.log(api);
        var datafromLogin = location.state.logindetails.split(',');
        document.getElementById('lblloggedUser').innerHTML = datafromLogin[1];
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];
        fetch(api + '/getlocations', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid1 }),
        })
            .then(response => {

                return response.text();
            })
            .then(data => {
                //alert(data);
                var obj = JSON.parse(data);
                var dropdown = document.getElementById('cmbLocationNames');
                var option = document.createElement("option");
                option.value = "--Select Location--";
                option.text = "--Select Location--";
                dropdown.appendChild(option);
                for (var i = 0; i < obj.length; i++) {
                    option = document.createElement("option");
                    option.value = obj[i].locationname;
                    option.text = obj[i].locationname;
                    dropdown.appendChild(option);
                }
            });
        var olidLocal = 0;
        fetch(api + '/getfeatureforrole1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectid1, userid1, olidLocal }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    if (obj[0].featurename == "Boundaries") {
                        document.getElementById('imgDrawing').style.display = 'inline';
                    }
                    else {
                        document.getElementById('imgDrawing').style.display = 'none';
                    }
                }
                else {
                    document.getElementById('imgDrawing').style.display = 'none';
                }
            });
        var categoryid1 = 0, categorycharacter1 = '';
        fetch(api + '/getCategories', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectid1, userid1, categoryid1, categorycharacter1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                var dropdown = document.getElementById('cmbFiltersCategory');
                var option = document.createElement("option");
                option.value = "--Select Category--";
                option.text = "--Select Category--";
                dropdown.appendChild(option);
                for (var i = 0; i < obj.length; i++) {
                    option = document.createElement("option");
                    option.value = obj[i].category;
                    option.text = obj[i].category;
                    dropdown.appendChild(option);
                }
            });
        fetch(api + '/getpropertynameslist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectid1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                setPropertyTextData(obj);
            });

        // get poi info and fill it in the filters dropdown...
        var emptystrng = '';
        fetch(api + '/getpoiinfo1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectid1, emptystrng }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                setPOIdata(obj);
            });
        // End

        // get all ofice types and load it in dropdown - filters
        fetch(api + '/getoffices', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectid1, userid1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                var dropdown = document.getElementById('cmbOfficetypesfilters');
                var dropdown1 = document.getElementById('cmbOfficetypesfiltersDrawPolygons');
                var option = document.createElement("option");
                option.value = "--Select office type--";
                option.text = "--Select office type--";
                dropdown.appendChild(option);
                var option1 = document.createElement("option");
                option1.value = "--Select office type--";
                option1.text = "--Select office type--";
                dropdown1.appendChild(option1);
                for (var i = 0; i < obj.length; i++) {
                    option = document.createElement("option");
                    option.value = obj[i].officetype;
                    option.text = obj[i].officetype;

                    option1 = document.createElement("option");
                    option1.value = obj[i].officetype;
                    option1.text = obj[i].officetype;

                    dropdown.appendChild(option);
                    dropdown1.appendChild(option1);
                }
            });

        // get all location names and load it in dropdown - filters

        // End

        //  get parent asset types to drodown

        fetch(api + '/getParentAssetTypes1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ projectid1, userid1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                var dropdown = document.getElementById('cmbParentAssetTypes');
                var option = document.createElement("option");
                option.value = "--Select Parent Asset Type--";
                option.text = "--Select Parent Asset Type--";
                dropdown.appendChild(option);
                for (var i = 0; i < obj.length; i++) {
                    option = document.createElement("option");
                    option.value = obj[i].assettypename;
                    option.text = obj[i].assettypename;
                    dropdown.appendChild(option);
                }
            });
        // End

        var layerOSM = new TileLayer({
            source: new OSM()
        });
        var BingImage = new TileLayer({
            preload: Infinity,
            source: new Bingmaps({
                key: "ApTJzdkyN1DdFKkRAE6QIDtzihNaf6IWJsT-nQ_2eMoO4PN__0Tzhl2-WgJtXFSp",
                imagerySet: 'Aerial',
                maxZoom: 2
            }),
            title: 'Bing Image'
        });
        var BingMap = new TileLayer({
            preload: Infinity,
            source: new Bingmaps({
                key: "ApTJzdkyN1DdFKkRAE6QIDtzihNaf6IWJsT-nQ_2eMoO4PN__0Tzhl2-WgJtXFSp",
                imagerySet: 'RoadOnDemand',
                maxZoom: 22
            }),
            title: 'Bing Map'
        });
        var BingImagewithLabels = new TileLayer({
            preload: Infinity,
            source: new Bingmaps({
                key: "ApTJzdkyN1DdFKkRAE6QIDtzihNaf6IWJsT-nQ_2eMoO4PN__0Tzhl2-WgJtXFSp",
                imagerySet: 'AerialWithLabels',
                maxZoom: 22
            }),
            title: 'Bing Map'
        });

        // Google Maps Terrain
        var GoogleMAPTerrain = new TileLayer({
            source: new XYZ({
                url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
            })
        });

        // USGS Topo
        var USGSTopo = new TileLayer({
            source: new XYZ({
                url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
            })
        });


        var noImage = new TileLayer({
            preload: Infinity,
            source: new Bingmaps({
                key: "-nQ_2eMoO4PN__0Tzhl2-WgJtXFSp",
                imagerySet: '',
                maxZoom: 22
            }),
            title: 'Bing Map'
        });

        BingImage.setVisible(false);
        BingMap.setVisible(false);
        BingImagewithLabels.setVisible(false);
        layerOSM.setVisible(true);
        GoogleMAPTerrain.setVisible(false);
        noImage.setVisible(false);
        USGSTopo.setVisible(false);

        setBingImage(BingImage);
        setBingMap(BingMap);
        setBingHybrid(BingImagewithLabels);
        setOSM(layerOSM);
        setGoogleTerrain(GoogleMAPTerrain);
        setusgsTopo(USGSTopo);
        setnoImage(noImage);

        // create map
        map = new Map({
            target: 'map',
            layers: [
                BingImage, BingMap, BingImagewithLabels, layerOSM, GoogleMAPTerrain, noImage, USGSTopo
            ],
            view: new View({
                projection: 'EPSG:4326',
                center: [80.3288, 23.5121],
                zoom: 4
            }),
            controls: []
        })

        // Select Feature Interaction
        var selectStyleFunction = new Style({
            fill: new Fill({
                color: 'rgba(0,255,255,0.5)',
            }),
            stroke: new Stroke({
                color: 'rgba(0,255,255, 1)',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: 'rgba(255,0,0 , 1)',
                }),
            }),

            text: new Text({
                font: '12px bold Montserrat',
                fill: new Fill({
                    color: 'rgba(255, 0, 0, 1.0)',
                }),
                backgroundFill: new Fill({
                    color: 'rgba(255, 255, 255, 0.3)',
                }),
                overflow: true,
                scale: 1.0,
                padding: [0, 5, 0, 5],
            })
        });

        var selectInteraction = new Select({
            condition: function (evt) {
                return evt.type === "singleclick";
            },
            style: function (feature) {

                return selectStyleFunction;
            }
        });
        map.addInteraction(selectInteraction);
        //  Vector for spatial query
        var sourceSPatialQuery = new VectorSource();

        var vectorSpatialQuery = new VectorLayer({
            source: sourceSPatialQuery,
            zIndex: 3000,
            style: new Style({
                stroke: new Stroke({
                    color: 'rgb(255,0,0)',
                    width: 2,
                }),
            }),
        });
        map.addLayer(vectorSpatialQuery);
        setSpatialQuerySOurce(sourceSPatialQuery);
        //  Measurement starts
        var source = new VectorSource();

        var vector = new VectorLayer({
            source: source,
            zIndex: 3000,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
                text: new Text({
                    font: '11px roboto,sans-serif',
                    fill: new Fill({
                        color: 'red'
                    }),
                    textAlign: 'center',
                    textBaseline: 'bottom',
                    offsetY: -5,
                    offsetX: -5
                }),
                image: new Circle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33',
                    }),
                }),
            }),
        });
        map.addLayer(vector);
        setsource(source);
        map.getViewport().addEventListener('mouseout', function () {
            helpTooltipElement.classList.add('hidden');
        });
        createHelpTooltip();
        createMeasureTooltip();
        // Measurement End..

        //On CLick started...
        var container = document.getElementById('popup');
        var content = document.getElementById('popup-content');
        var closer = document.getElementById('popup-closer');
        /**
         * Create an overlay to anchor the popup to the map.
         */
        var overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });
        map.addOverlay(overlay);

        /**
         * Add a click handler to hide the popup.
         * @return {boolean} Don't follow the href.
         */
        closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        };
        map.on('click', function (evt) {
            var imginfoButton = document.getElementById('imgInfo');
            var coordinate = evt.coordinate;
            map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                setSelectedFeature(feature);
                setDistributionNodeID(feature.get('Distribution Node ID'))
                if (imginfoButton.style.backgroundColor == 'rgb(3, 54, 105)') {
                    overlay.setPosition(undefined);
                    closer.blur();
                    var assetid1 = feature.get('assetid');
                    var indicatorid1 = feature.get('indicatorid');
                    fetch(api + '/getpopupdata1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ assetid1, indicatorid1 }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj = JSON.parse(data);
                            if (obj.length > 0) {
                                var markup = "<table border='1' class='table table-striped'>";
                                for (var i = 0; i < obj[0].pvalues.length; i++) {
                                    markup += "<tr ><td > " + obj[0].pvalues[i].pname + "</td><td >" + obj[0].pvalues[i].pvalue + "</td></tr> ";
                                }
                                markup += '</table>';
                                document.getElementById('popupDIV').style.display = 'inline';
                                document.getElementById('popupcontent').innerHTML = markup;
                                DisplayPopupIMage(obj, 0);
                            }
                        });
                }
            })
        });

        function DisplayPopupIMage(jsonObject, currentIndex) {
            fetchImage(jsonObject[0].imagedata[currentIndex].imglink).then(res => {
                document.getElementById('imgPopupDataImage').src = res
            })
            document.getElementById('imgpopupImageName').innerHTML = jsonObject[0].imagedata[currentIndex].title;
            var btnNext = document.getElementById('btnPopupImageNext');
            var btnPrevious = document.getElementById('btnPopupImagePrevious');
            var currentIndex1 = currentIndex;
            btnNext.onclick = function () {
                currentIndex1 = currentIndex1 + 1;
                if (currentIndex1 > jsonObject[0].imagedata.length - 1) {
                    currentIndex1 = currentIndex1 - 1;
                }
                fetchImage(jsonObject[0].imagedata[currentIndex1].imglink).then(res => {
                    document.getElementById('imgPopupDataImage').src = res
                })
                document.getElementById('imgpopupImageName').innerHTML = jsonObject[0].imagedata[currentIndex1].title;
            }

            btnPrevious.onclick = function () {
                currentIndex1 = currentIndex1 - 1;
                if (currentIndex1 < 0) {
                    currentIndex1 = currentIndex1 + 1;
                }
                fetchImage(jsonObject[0].imagedata[currentIndex1].imglink).then(res => {
                    document.getElementById('imgPopupDataImage').src = res
                })
                document.getElementById('imgpopupImageName').innerHTML = jsonObject[0].imagedata[currentIndex1].title;
            }
        }

        map.on('pointermove', function (evt) {
            var stringifyFunc = createStringXY(6);
            var out = stringifyFunc(evt.coordinate);
            if (document.getElementById("cDisId").value === "decimal") {
                document.getElementById('lblCoordinates').innerHTML = "Latitude:" + out.toString().split(',')[1] + " , Longitude:" + out.toString().split(',')[0];
            }
            if (document.getElementById("cDisId").value === "dms") {
                var longitude = parseFloat(out.toString().split(',')[0]);
                var longDeg = parseInt(longitude);
                var longMin = parseInt((longitude - parseInt(longitude)) * 60);
                var longSec = ((longitude - longDeg - (longMin / 60)) * 3600).toFixed(1);
                var longString = longDeg + '\u00B0 ' + longMin + '\' ' + longSec + '"';
                var latitude = parseFloat(out.toString().split(',')[1]);
                var latDeg = parseInt(latitude);
                var latMin = parseInt((latitude - parseInt(latitude)) * 60);
                var latSec = ((latitude - latDeg - (latMin / 60)) * 3600).toFixed(1);
                var latString = latDeg + '\u00B0 ' + latMin + '\' ' + latSec + '"';
                document.getElementById('lblCoordinates').innerHTML = "Latitude:" + latString + " , Longitude:" + longString;
            }

            if (document.getElementById('imgInfo').style.backgroundColor == 'rgb(3, 54, 105)') {
                var coordinate = evt.coordinate;
                var FeatureFlag = false;

                map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                    if (feature) {
                        FeatureFlag = true;
                        var markup = "<table border='1'>";
                        var properties = feature.getProperties();
                        for (var property in properties) {
                            if (property !== "geometry" && property !== "Distribution Node ID" && property !== "assetid" && property !== "indicatorid" && property !== "Distribution Node Type Name" && property !== "Span" && property !== "Icon")
                                markup += "<tr style={{width:'180px'}}><td style={{width:'100px'}}> " + property + "</td><td style={{width:'100px'}}>" + feature.get(property) + "</td></tr> ";
                        }
                        markup += '</table>';
                        content.innerHTML = markup;
                        overlay.setPosition(coordinate);
                    }
                });
                if (FeatureFlag == false) {
                    overlay.setPosition(undefined);
                    closer.blur();
                }
            }
        });

        var scalecontrol = new scaleLine({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140,
        });
        map.addControl(scalecontrol);

        var fullscreen = new fullScreen();
        map.addControl(fullscreen);

        // save map and vector layer references to state
        setMap(map);
        setScaleControl(scalecontrol);



        // Add asset layer  on map

        var assetsSource = new VectorSource({
            wrapX: false
        });

        const assetclusterSource = new Cluster({
            distance: 500,
        });

        var assetsLayer = new VectorLayer({
            source: assetsSource,
        });
        //End

        // Create polyline layer(connectivity) and add to map

        var connectivitySource = new VectorSource({
            wrapX: false
        });

        const connectivityclusterSource = new Cluster({
            distance: 100,
        });

        var connectivityStyle = new Style({
            stroke: new Stroke({
                color: 'rgba(255,0,255, 1)',
                width: 2
            }),
        });

        var conectivityLayer = new VectorLayer({
            source: connectivitySource,
            style: connectivityStyle,
            minZoom: 7, // visible at zoom levels above 14

        });
        map.addLayer(conectivityLayer);
        setconnectivitySorce(connectivitySource);
        setconnectivityLayer(conectivityLayer);

        // For drawing polygon
        drawPolgonSource = new VectorSource({ wrapX: false });

        var drawPolygonLayer = new VectorLayer({
            source: drawPolgonSource,
            style: new Style({
                stroke: new Stroke({
                    color: 'red',
                    width: 3,
                }),
            }),
        });
        map.addLayer(drawPolygonLayer);
        setDrawpolygonSource(drawPolgonSource);
        map.addLayer(assetsLayer);
        setassetsSorce(assetsSource);

        //To display text in print
        var printTextSource = new VectorSource({
            wrapX: false
        });

        var printTextLayer = new VectorLayer({
            source: printTextSource,
        });

        setPrintTextSorce(printTextSource);
        map.addLayer(printTextLayer);
        const modify = new Modify({ source: printTextSource });
        map.addInteraction(modify);
        getBoundaryForUser(userid1, map.getView())

    }, [])

    const findExtent = (coords) => {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        coords.forEach(coord => {
            minX = Math.min(minX, coord[0]);
            minY = Math.min(minY, coord[1]);
            maxX = Math.max(maxX, coord[0]);
            maxY = Math.max(maxY, coord[1]);
        });

        return [minX, minY, maxX, maxY];
    }


    function getBoundaryForUser(userId, map) {

        fetch(api + '/getboundaryforuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data)
                let latLng = []
                if (obj.length > 0) {
                    for (let eachObj of obj) {
                        latLng = JSON.parse(eachObj.geojsonboundary).coordinates[0]
                    }
                    const coords = latLng.map(location => {
                        return [location[1], location[0]];
                    });

                    const extent = findExtent(coords)
                    map.setCenter([extent[1], extent[0]])
                    map.setZoom(10);
                }
            })
    }

    function handlePanClick() {
        mapforArrowFunction.removeInteraction(rubberbandZoomInteractionOut);
    }

    const handleClickEventZoomIn = () => {
        mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() + 1);
    }
    var geometryFunction = createBox();

    function handleClickEventRubberbandZoom() {
        mapforArrowFunction.removeInteraction(rubberbandZoomInteractionOut);
        var drawInteractionforRubberandZoom = new Draw({
            source: new VectorSource({ wrapX: false }),
            type: 'Circle',
            geometryFunction: geometryFunction,
        });
        mapforArrowFunction.addInteraction(drawInteractionforRubberandZoom);
        setRubberbandZoom(drawInteractionforRubberandZoom);
        drawInteractionforRubberandZoom.on("drawend", function (e) {

            let DrawExtent = e.feature.getGeometry().getExtent();
            mapforArrowFunction.getView().fit(DrawExtent, mapforArrowFunction.getSize());
        });
    }


    const handleClickEventZoomOut = () => {
        mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() - 1);
    }

    function handleClickEventInfo(e) {
        if (e.target.style.backgroundColor == 'white') {
            e.target.style.backgroundColor = 'rgb(3, 54, 105)';
        }
        else {
            e.target.style.backgroundColor = 'white';
        }
    }

    const closeTreeviewDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'none';
    }

    const closeMeasureDIV = () => {
        document.getElementById('MeasurementDIV').style.display = 'none';
        mapforArrowFunction.removeInteraction(drawMeasureOut);
        outSource.clear();
        mapforArrowFunction.getOverlays().getArray().slice(0).forEach(function (overlay) {
            if (overlay.id === "measureTooltip") {
                mapforArrowFunction.removeOverlay(overlay);
            }
        });
    }

    const closefiltersDIV = () => {
        document.getElementById('filtersDIV').style.display = 'none';
        onclearSpatialQueryPolygons();
    }

    const closeprintDIV = () => {
        document.getElementById('printDIV').style.display = 'none';
    }

    const showTreeviewDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'inline';
        document.getElementById('MeasurementDIV').style.display = 'none';
        document.getElementById('printDIV').style.display = 'none';
        document.getElementById('filtersDIV').style.display = 'none';
        document.getElementById('exportDIV').style.display = 'none';
        document.getElementById('importDIV').style.display = 'none';
        document.getElementById('DrawingDIV').style.display = 'none';
    }

    const showPrintDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'none';
        document.getElementById('MeasurementDIV').style.display = 'none';
        document.getElementById('printDIV').style.display = 'inline';
        document.getElementById('filtersDIV').style.display = 'none';
        document.getElementById('exportDIV').style.display = 'none';
        document.getElementById('importDIV').style.display = 'none';
        document.getElementById('DrawingDIV').style.display = 'none';
    }

    const showMeasureDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'none';
        document.getElementById('MeasurementDIV').style.display = 'inline';
        document.getElementById('printDIV').style.display = 'none';
        document.getElementById('filtersDIV').style.display = 'none';
        document.getElementById('exportDIV').style.display = 'none';
        document.getElementById('importDIV').style.display = 'none';
        document.getElementById('DrawingDIV').style.display = 'none';
    }

    const showDrawingDIV = () => {
        var select = document.getElementById('cmbOfficetypesfiltersDrawPolygons');
        select.selectedIndex = 0;
        document.getElementById('cmlocationsfiltersDrawPolygons').value = "";
        document.getElementById('DrawingDIV').style.display = 'inline';
        document.getElementById('TreeviewDIV').style.display = 'none';
        document.getElementById('MeasurementDIV').style.display = 'none';
        document.getElementById('printDIV').style.display = 'none';
        document.getElementById('filtersDIV').style.display = 'none';
        document.getElementById('exportDIV').style.display = 'none';
        document.getElementById('importDIV').style.display = 'none';
    }

    const showfiltersDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'none';
        document.getElementById('MeasurementDIV').style.display = 'none';
        document.getElementById('printDIV').style.display = 'none';
        document.getElementById('filtersDIV').style.display = 'inline';
        document.getElementById('exportDIV').style.display = 'none';
        document.getElementById('importDIV').style.display = 'none';
        document.getElementById('DrawingDIV').style.display = 'none';
    }

    const closeDrawingDIV = () => {
        document.getElementById('DrawingDIV').style.display = 'none';
        mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
    }

    const closeExportDIV = () => {
        document.getElementById('exportDIV').style.display = 'none';
    }

    const closeImportDIV = () => {
        document.getElementById('importDIV').style.display = 'none';
    }

    const closepopupDIV = () => {
        document.getElementById('popupDIV').style.display = 'none';
    }

    const showExportDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'none';
        document.getElementById('MeasurementDIV').style.display = 'none';
        document.getElementById('printDIV').style.display = 'none';
        document.getElementById('filtersDIV').style.display = 'none';
        document.getElementById('exportDIV').style.display = 'inline';
        document.getElementById('importDIV').style.display = 'none';
        document.getElementById('DrawingDIV').style.display = 'none';
    }

    const showImportDIV = () => {
        document.getElementById('TreeviewDIV').style.display = 'none';
        document.getElementById('MeasurementDIV').style.display = 'none';
        document.getElementById('printDIV').style.display = 'none';
        document.getElementById('filtersDIV').style.display = 'none';
        document.getElementById('exportDIV').style.display = 'none';
        document.getElementById('importDIV').style.display = 'inline';
        document.getElementById('DrawingDIV').style.display = 'none';
    }

    function changeBackground(e) {
        if (e.target.style.backgroundColor !== 'rgb(26, 97, 255)') {
            e.target.style.backgroundColor = '#2b3d51';
        }
    }

    function KeepBackground(e) {
        if (e.target.style.backgroundColor !== 'rgb(26, 97, 255)') {
            e.target.style.backgroundColor = 'white';
        }
    }

    function changeBackground1(e) {
        if (e.target.style.backgroundColor !== 'rgb(26, 97, 255)') {
            e.target.style.backgroundColor = 'rgb(211, 211, 211)';
        }
    }

    function KeepBackground1(e) {
        if (e.target.style.backgroundColor !== 'rgb(26, 97, 255)') {
            e.target.style.backgroundColor = 'white';
        }
    }

    // Measure functions
    let globalLocationID = 0;
    var areaUnit = "acres";
    var lengthUnit = "meters";
    var coorUnit = "decimal";
    var drawSnapType = "";
    var drawMeasure;
    var sketch;
    var helpTooltipElement;
    var helpTooltip;
    var measureTooltipElement;
    var measureTooltip;
    var continuePolygonMsg = 'Double click to end drawing the polygon';
    var continueLineMsg = 'Double click to end drawing the line';

    function aUnitChange() {

    }
    function cDisChange() {


    }
    function lUnitChange() {

    }


    var formatArea = function (polygon) {
        var area = polygon.clone().transform('EPSG:4326', 'EPSG:3857').getArea();
        if (document.getElementById("aUniId").value === "acres") {
            if (area < 0)
                area = 0;
            var output;
            if (area > 10000) {
                output = (((area * 247.105 / 1000000) * 100) / 100).toFixed(3) + ' ' + 'acres';
            } else {
                output = (area * 0.000247105 * 100 / 100).toFixed(3) + ' ' + 'acres';
            }
            return output;
        }
        if (document.getElementById("aUniId").value === "sqMeters") {
            if (area < 0)
                area = 0;
            var output;
            output = (area * 100 / 100).toFixed(3) + ' ' + 'Sq. Mts.';
            return output;
        }
        if (document.getElementById("aUniId").value === "sqKms") {
            if (area < 0)
                area = 0;
            var output;
            output = (area / 1000000 * 100 / 100).toFixed(3) + ' ' + 'Sq. KMs.';
            return output;
        }
        if (document.getElementById("aUniId").value === "sqFeet") {
            if (area < 0)
                area = 0;
            var output;
            output = (area * 10.764 * 100 / 100).toFixed(3) + ' ' + 'Sq. Footage';
            return output;
        }
        if (document.getElementById("aUniId").value === "hectares") {
            if (area < 0)
                area = 0;
            var output;
            output = (area / 10000 * 100 / 100).toFixed(3) + ' ' + 'Hectares';
            return output;
        }
    };


    var formatLength = function (line) {
        var length = line.clone().transform('EPSG:4326', 'EPSG:3857').getLength();
        if (document.getElementById("lUniId").value === "meters") {
            var output;
            output = Math.round(length * 100) / 100 + ' ' + 'm';
            return output;
        }
        if (document.getElementById("lUniId").value == "kms") {
            output = Math.round((length / 1000) * 100) / 100 + ' ' + 'kms';
            return output;
        }
        if (document.getElementById("lUniId").value === "feets") {
            var output;
            output = Math.round(length * 3.28084 * 100) / 100;
            output = output.toString().split('.')[0] + '\' ' + (parseFloat(output.toString().split('.')[1]) * 12 / 100) + '"';
            return output;
        }
        if (document.getElementById("lUniId").value === "miles") {
            var output;
            output = Math.round(length / 1852 * 100) / 100 + ' ' + 'Nautical Miles';
            return output;
        }
    };

    function createHelpTooltip() {
        if (helpTooltipElement) {
            helpTooltipElement.parentNode.removeChild(helpTooltipElement);
        }
        helpTooltipElement = document.createElement('div');
        helpTooltipElement.className = 'ol-tooltip hidden';
        helpTooltip = new Overlay({
            element: helpTooltipElement,
            offset: [15, 0],
            positioning: 'center-left',
        });
        map.addOverlay(helpTooltip);
    }

    function createMeasureTooltip() {
        if (measureTooltipElement) {
            measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }
        measureTooltipElement = document.createElement('div');
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        measureTooltip = new Overlay({
            id: "measureTooltip",
            element: measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        });
        map.addOverlay(measureTooltip);
    }

    function createHelpTooltip1() {

        if (helpTooltipElement) {
            helpTooltipElement.parentNode.removeChild(helpTooltipElement);
        }
        helpTooltipElement = document.createElement('div');
        helpTooltipElement.className = 'ol-tooltip hidden';
        helpTooltip = new Overlay({
            element: helpTooltipElement,
            offset: [15, 0],
            positioning: 'center-left',
        });
        mapforArrowFunction.addOverlay(helpTooltip);
    }

    function createMeasureTooltip1() {
        if (measureTooltipElement) {
            measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }
        measureTooltipElement = document.createElement('div');
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        measureTooltip = new Overlay({
            id: "measureTooltip",
            element: measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        });
        mapforArrowFunction.addOverlay(measureTooltip);
    }

    // function to change draw interaction
    function drawLine() {
        drawSnapType = 'LineString';
        mapforArrowFunction.removeInteraction(drawMeasureOut);
        addInteractionMeasure("LineString");
    }

    // function to change draw interaction
    function drawArea() {
        drawSnapType = 'Polygon';
        mapforArrowFunction.removeInteraction(drawMeasureOut);
        addInteractionMeasure("Polygon");
    }

    // Function to draw the measurement line
    function addInteractionMeasure(type) {
        drawMeasure = new Draw({
            source: outSource,
            type: type,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: 'rgba(255,69,0, 1)',
                    lineDash: [10, 10],
                    width: 2,
                }),
                image: new Circle({
                    radius: 5,
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.7)',
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)',
                    }),
                }),
            }),
        });
        mapforArrowFunction.addInteraction(drawMeasure);
        setDrawMeasure(drawMeasure);
        createMeasureTooltip1();
        createHelpTooltip1();
        var listener;
        drawMeasure.on('drawstart', function (evt) {
            // set sketch
            sketch = evt.feature;
            var tooltipCoord = evt.coordinate;
            listener = sketch.getGeometry().on('change', function (evt) {
                var geom = evt.target;
                var output;
                if (geom instanceof Polygon) {
                    output = formatArea(geom);
                    tooltipCoord = geom.getInteriorPoint().getCoordinates();
                } else if (geom instanceof LineString) {
                    output = formatLength(geom);
                    tooltipCoord = geom.getLastCoordinate();
                }
                measureTooltipElement.innerHTML = output;
                measureTooltip.setPosition(tooltipCoord);
                document.getElementById('lblMeasureReults').innerHTML = "Results: " + output;
            });
        });

        drawMeasure.on('drawend', function () {
            measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
            measureTooltip.setOffset([0, -7]);
            // unset sketch
            sketch = null;
            // unset tooltip so that a new one can be created
            measureTooltipElement = null;
            createMeasureTooltip1();
            unByKey(listener);
        });
    }
    function snapToLayer() {

    }

    //End
    const handleClickEventclearSelection = () => {
        mapforArrowFunction.removeInteraction(drawMeasureOut);
        mapforArrowFunction.removeInteraction(rubberbandZoomInteractionOut);

        outSource.clear();
        printTextSourceforArrowFunction.clear();
        drawPolgonSourceArrowfunction.clear();
        mapforArrowFunction.getOverlays().getArray().slice(0).forEach(function (overlay) {
            if (overlay.id === "measureTooltip") {
                mapforArrowFunction.removeOverlay(overlay);
            }
        });
        document.getElementById('lblMeasureReults').innerHTML = '';
        mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
        spatialQuerySourceOut.clear();
    }


    const showOSM = (e) => {
        document.getElementById('osmmap').checked = true;
        bingImageOut.setVisible(false);
        bingMapOut.setVisible(false);
        bingHybridOut.setVisible(false);
        osmOut.setVisible(true);
        usgsTopoOut.setVisible(false);
        googleMapTerainOut.setVisible(false);
        noImageOut.setVisible(false);
        document.getElementById('osmmap').checked = true;
        document.getElementById('bingmap').checked = false;
        document.getElementById('bingimagery').checked = false;
        document.getElementById('bingimagerywithlabels').checked = false;
        document.getElementById('googleterrain').checked = false;
        document.getElementById('usgstopo').checked = false;
        document.getElementById('none').checked = false;
    }

    const showBingImagery = () => {
        bingImageOut.setVisible(true);
        bingMapOut.setVisible(false);
        bingHybridOut.setVisible(false);
        osmOut.setVisible(false);
        usgsTopoOut.setVisible(false);
        googleMapTerainOut.setVisible(false);
        noImageOut.setVisible(false);
        document.getElementById('osmmap').checked = false;
        document.getElementById('bingmap').checked = false;
        document.getElementById('bingimagery').checked = true;
        document.getElementById('bingimagerywithlabels').checked = false;
        document.getElementById('googleterrain').checked = false;
        document.getElementById('usgstopo').checked = false;
        document.getElementById('none').checked = false;
    }

    const showBIngMap = () => {
        bingImageOut.setVisible(false);
        bingMapOut.setVisible(true);
        bingHybridOut.setVisible(false);
        osmOut.setVisible(false);
        usgsTopoOut.setVisible(false);
        googleMapTerainOut.setVisible(false);
        noImageOut.setVisible(false);
        document.getElementById('osmmap').checked = false;
        document.getElementById('bingmap').checked = true;
        document.getElementById('bingimagery').checked = false;
        document.getElementById('bingimagerywithlabels').checked = false;
        document.getElementById('googleterrain').checked = false;
        document.getElementById('usgstopo').checked = false;
        document.getElementById('none').checked = false;
    }

    const showBingImagerywithLabels = () => {
        bingImageOut.setVisible(false);
        bingMapOut.setVisible(false);
        bingHybridOut.setVisible(true);
        osmOut.setVisible(false);
        usgsTopoOut.setVisible(false);
        googleMapTerainOut.setVisible(false);
        noImageOut.setVisible(false);
        document.getElementById('osmmap').checked = false;
        document.getElementById('bingmap').checked = false;
        document.getElementById('bingimagery').checked = false;
        document.getElementById('bingimagerywithlabels').checked = true;
        document.getElementById('googleterrain').checked = false;
        document.getElementById('usgstopo').checked = false;
        document.getElementById('none').checked = false;
    }

    const shownoimage = () => {
        bingImageOut.setVisible(false);
        bingMapOut.setVisible(false);
        bingHybridOut.setVisible(false);
        osmOut.setVisible(false);
        usgsTopoOut.setVisible(false);
        googleMapTerainOut.setVisible(false);
        noImageOut.setVisible(true);
        document.getElementById('osmmap').checked = false;
        document.getElementById('bingmap').checked = false;
        document.getElementById('bingimagery').checked = false;
        document.getElementById('bingimagerywithlabels').checked = false;
        document.getElementById('googleterrain').checked = false;
        document.getElementById('usgstopo').checked = false;
        document.getElementById('none').checked = true;
    }

    const showgoogleTerrainMap = () => {
        bingImageOut.setVisible(false);
        bingMapOut.setVisible(false);
        bingHybridOut.setVisible(false);
        osmOut.setVisible(false);
        usgsTopoOut.setVisible(false);
        googleMapTerainOut.setVisible(true);
        noImageOut.setVisible(false);
        document.getElementById('osmmap').checked = false;
        document.getElementById('bingmap').checked = false;
        document.getElementById('bingimagery').checked = false;
        document.getElementById('bingimagerywithlabels').checked = false;
        document.getElementById('googleterrain').checked = true;
        document.getElementById('usgstopo').checked = false;
        document.getElementById('none').checked = false;
    }

    const showusgsTopoMap = () => {
        bingImageOut.setVisible(false);
        bingMapOut.setVisible(false);
        bingHybridOut.setVisible(false);
        osmOut.setVisible(false);
        usgsTopoOut.setVisible(true);
        googleMapTerainOut.setVisible(false);
        noImageOut.setVisible(false);
        document.getElementById('osmmap').checked = false;
        document.getElementById('bingmap').checked = false;
        document.getElementById('bingimagery').checked = false;
        document.getElementById('bingimagerywithlabels').checked = false;
        document.getElementById('googleterrain').checked = false;
        document.getElementById('usgstopo').checked = true;
        document.getElementById('none').checked = false;
    }

    function addassettypestocheckoxes(assetname) {
        var table = document.getElementById("filterAssettypesTable");
        table.border = 0;
        var row;
        if (table.getElementsByTagName("tr").length == 0) {

            row = table.insertRow(0);
        }
        else {
            row = table.rows[table.getElementsByTagName("tr").length - 1];
            if (row.getElementsByTagName("td").length > 6) {
                row = table.insertRow(table.getElementsByTagName("tr").length);
            }
        }
        var cell1 = row.insertCell(0);
        var cell2 = row.insertCell(1);
        cell2.innerHTML = assetname;

        var chkbox = document.createElement('input');
        chkbox.type = "checkbox";
        chkbox.id = "chk";
        chkbox.text = assetname;
        chkbox.checked = false;
        chkbox.onclick = function () {
            if (chkbox.checked) {
                assettypesArray.push(assetname);
            }
            else {
                var myIndex = assettypesArray.indexOf(assetname);
                if (myIndex !== -1) {
                    assettypesArray.splice(myIndex, 1);
                }
            }
        }

        // creating label for checkbox
        var label = document.createElement('label');
        // assigning attributes for
        // the created label tag
        label.htmlFor = "id";
        // appending the created text to
        // the created label tag
        label.appendChild(document.createTextNode(assetname));
        cell1.appendChild(chkbox);
    }

    function onResetFilters() {
        assetsSourceforArrowFunction.clear();
        connectivitySourceforArrowFunction.clear();
        document.getElementById('cmbParentAssets').innerHTML = '';
        document.getElementById('chkAdvancedFilter1').checked = false;
        document.getElementById('txtAssetcodeAdvancedFilters1').value = '';
        document.getElementById('txtAssetnameAdvancedFilters1').value = '';
        document.getElementById('txtAssetcodeAdvancedFilters1').disabled = true;
        document.getElementById('txtAssetnameAdvancedFilters1').disabled = true;
        document.getElementById('chkAdvancedFilter2').checked = false;
        document.getElementById('txtPOIAdvancedfilters2').disabled = true;
        document.getElementById('chkAdvancedFilters3').checked = false;
        document.getElementById('cmbPOIAdvancedfilters3').disabled = true;
        document.getElementById('chkSPatilQueryFilters').checked = false;
        document.getElementById('btnDrawpolygonFiltersSpatialQuery').style.display = 'none';
        document.getElementById('btnClearSparialQueryPolygons').style.display = 'none';
        document.getElementById('lblInsideBoundary').style.display = 'none';
        document.getElementById('chkInsideBoundary').style.display = 'none';
        var select = document.getElementById('cmbOfficetypesfilters');
        select.selectedIndex = 0;
        select = document.getElementById('cmbFiltersCategory');
        select.selectedIndex = 0;
        document.getElementById('cmlocationsfilters').value = '';
        select = document.getElementById('cmbParentAssetTypes');
        select.selectedIndex = 0;
        select = document.getElementById('cmbParentAssets');
        select.selectedIndex = 0;
        document.getElementById('txtAssetcodeAdvancedFilters1').value = '';
        document.getElementById('txtAssetnameAdvancedFilters1').value = '';
        document.getElementById('txtPOIAdvancedfilters2').value = '';
        document.getElementById('cmbPOIAdvancedfilters3').value = '';
        document.getElementById('lblAssettypes').style.display = 'none';
        document.getElementById('filterAssettypesTable').style.display = 'none';
        document.getElementById('lblofficetypes').style.display = 'none';
        document.getElementById('cmbOfficetypesfilters').style.display = 'none';
        document.getElementById('lblLocation').style.display = 'none';
        document.getElementById('cmlocationsfilters').style.display = 'none';
        document.getElementById('FiltersTable').style.display = 'none';
        document.getElementById('DIVAdvancedFilters').style.display = 'none';
        document.getElementById('DIVSpatialQuery').style.display = 'none';
        document.getElementById('lblparenthierarchy').style.display = 'none';
        document.getElementById('lblLocationHeading').style.display = 'none';
        document.getElementById('cmbParentAssetTypes').style.display = 'none';
        document.getElementById('cmbParentAssets').style.display = 'none';
        document.getElementById('lblParentAssettypes').style.display = 'none';
        document.getElementById('lblParentAssets').style.display = 'none';
        document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
        document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
        document.getElementById('txtAdvancedFilters3').style.display = 'none';
        document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
        document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
        document.getElementById('cmbAdvancedfilters3').style.display = 'none';
        onclearSpatialQueryPolygons();
        document.getElementById("tblBottomTable").innerHTML = "";
        document.getElementById('lblTotalRecordinBottomTable').innerHTML = '';
        document.getElementById('selectedCategory').innerHTML = '';
        document.getElementById('indicatoreName').innerHTML = '';
    }

    function onselectedCategory() {
        document.getElementById('lblAssettypes').style.display = 'inline';
        document.getElementById('filterAssettypesTable').style.display = 'inline';
        document.getElementById('lblofficetypes').style.display = 'inline';
        document.getElementById('cmbOfficetypesfilters').style.display = 'inline';
        document.getElementById('lblLocation').style.display = 'inline';
        document.getElementById('cmlocationsfilters').style.display = 'inline';
        document.getElementById('FiltersTable').style.display = 'inline';
        document.getElementById('DIVAdvancedFilters').style.display = 'inline';
        document.getElementById('DIVSpatialQuery').style.display = 'inline';
        document.getElementById('lblparenthierarchy').style.display = 'inline';
        document.getElementById('lblLocationHeading').style.display = 'inline';
        document.getElementById('cmbParentAssetTypes').style.display = 'inline';
        document.getElementById('cmbParentAssets').style.display = 'inline';
        document.getElementById('lblParentAssettypes').style.display = 'inline';
        document.getElementById('lblParentAssets').style.display = 'inline';
        document.getElementById('FiltersTable').innerHTML = "";
        document.getElementById('filterAssettypesTable').innerHTML = "";
        document.getElementById('cmlocationsfilters').value = '';
        var select = document.getElementById('cmbOfficetypesfilters');
        select.selectedIndex = 0;
        select = document.getElementById('cmbParentAssetTypes');
        select.selectedIndex = 0;
        select = document.getElementById('cmbParentAssets');
        select.length = 0;
        document.getElementById('chkAdvancedFilter1').checked = false;
        document.getElementById('txtAssetcodeAdvancedFilters1').value = '';
        document.getElementById('txtAssetnameAdvancedFilters1').value = '';
        document.getElementById('txtAssetcodeAdvancedFilters1').disabled = true;
        document.getElementById('txtAssetnameAdvancedFilters1').disabled = true;
        document.getElementById('chkAdvancedFilter2').checked = false;
        document.getElementById('txtPOIAdvancedfilters2').value = '';
        document.getElementById('txtPOIAdvancedfilters2').disabled = true;
        document.getElementById('chkAdvancedFilters3').checked = false;
        document.getElementById('cmbPOIAdvancedfilters3').disabled = true;
        document.getElementById('cmbPOIAdvancedfilters3').value = '';
        document.getElementById('txtfromDateAdvancedFilters3').value = '';
        document.getElementById('txttoDateAdvancedFilters3').value = '';
        document.getElementById('txtFromintegerAdvancedFilters3').value = '';
        document.getElementById('txtTointegerAdvancedFilters3').value = '';
        document.getElementById('txtAdvancedFilters3').value = '';
        document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
        document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
        document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
        document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
        document.getElementById('cmbAdvancedfilters3').style.display = 'none';
        document.getElementById('txtAdvancedFilters3').style.display = 'none';
        document.getElementById('chkSPatilQueryFilters').checked = false;
        document.getElementById('btnDrawpolygonFiltersSpatialQuery').style.display = 'none';
        document.getElementById('btnClearSparialQueryPolygons').style.display = 'none';
        document.getElementById('lblInsideBoundary').style.display = 'none';
        document.getElementById('chkInsideBoundary').style.display = 'none';
        onclearSpatialQueryPolygons();

        // Get all asset types and add it to checkoxes - filters 
        fetch(api + '/getassetstypes', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                for (var i = 0; i < obj.length; i++) {
                    addassettypestocheckoxes(obj[i].distributionnodetypename);
                }
            });

        // Start - get category id for selected category name
        document.getElementById("FiltersTable").innerHTML = "";
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];
        var select = document.getElementById('cmbFiltersCategory');
        var seelctedCategory = select.options[select.selectedIndex].value;
        var categoryID = 0;
        var categoryname = seelctedCategory;
        fetch(api + '/getcategoryID', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ categoryname }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                categoryID = obj[0].categoryid;

                // get indicaters for selected category id
                fetch(api + '/getIndicaters', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ projectid1, userid1, categoryID }),
                })
                    .then(response => {
                        return response.text();
                    })
                    .then(data => {
                        var obj = JSON.parse(data);
                        for (var i = 0; i < obj.length; i++) {
                            addIndicaterstoTable(obj[i].indicator, obj[i].datatype);
                        }
                    });
            });

        // End
    }

    function onChangePOIData(POItext) {
        let matches = [];
        if (POItext.length > 0) {
            matches = POIsData.filter(usr => {
                const regex = new RegExp(`${POItext}`, "gi");
                return usr.strg.match(regex)
            })
        }
        setSuggestions(matches);
        setPOIText(POItext);
    }

    function onChangeLocationDrawPolygon(text) {
        let matches = [];
        if (text.length > 0) {
            matches = locationsinDrawPolygon.filter(usr => {
                //alert(usr.location);
                const regex = new RegExp(`${text}`, "gi");
                // alert(regex);
                return usr.location.match(regex)
            })
        }
        setSuggestions(matches);
        setText(text);
    }

    function onChangeLocation(text) {
        let matches = [];
        if (text.length > 0) {
            matches = locations.filter(usr => {
                //alert(usr.location);
                const regex = new RegExp(`${text}`, "gi");
                // alert(regex);
                return usr.location.match(regex)
            })
        }
        setSuggestions(matches);
        setText(text);
    }

    function onChangeProperrtyData(PropertyText) {
        let matches = [];
        if (PropertyText.length > 0) {
            matches = PropertyTextsData.filter(usr => {
                const regex = new RegExp(`${PropertyText}`, "gi");
                return usr.propertyname.match(regex)
            })
        }
        setSuggestions(matches);
        setPropertyText(PropertyText);
    }

    function onSuggestHandler(text) {
        setText(text);
        setSuggestions([]);
        var locationid1 = 0;
        setlocationIDinAdavcendFIlters(0);
        var selectedLocation = text;

        fetch(api + '/getlocationid1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ selectedLocation }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var locationID = obj[0].officelocationallocationid;
                    setlocationIDinAdavcendFIlters(locationID);

                    locationid1 = locationID;

                    fetch(api + '/getBoundary1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ locationid1 }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj = JSON.parse(data);
                            if (obj.length > 0) {
                                drawPolgonSourceArrowfunction.clear();
                                var format = new WKT();
                                var feature = format.readFeature(obj[0].st_astext, {
                                    dataProjection: 'EPSG:4326',
                                    featureProjection: 'EPSG:4326',
                                });
                                drawPolgonSourceArrowfunction.addFeature(feature);
                                var extent = drawPolgonSourceArrowfunction.getExtent();
                                mapforArrowFunction.getView().fit(extent);
                                mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() - 1);
                            }
                        });
                }
            });
    }

    function onSuggestHandlerDrawPolygon(text) {
        setText(text);
        setSuggestions([]);
        setlocationIDinAdavcendFIlters(0);
        var selectedLocation = text;

        fetch(api + '/getlocationid1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ selectedLocation }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var locationID = obj[0].officelocationallocationid;
                    setlocationIDinAdavcendFIlters(locationID);
                }
            });
    }

    function onSuggestHandlerPOI(POItext) {
        setPOIText(POItext);
        setSuggestions([]);
    }

    function onSuggestHandlerPropertyData(PropertyText) {
        document.getElementById('txtfromDateAdvancedFilters3').value = '';
        document.getElementById('txttoDateAdvancedFilters3').value = '';
        document.getElementById('txtFromintegerAdvancedFilters3').value = 0;
        document.getElementById('txtTointegerAdvancedFilters3').value = 0;
        document.getElementById('txtAdvancedFilters3').value = '';
        setPropertyText(PropertyText);
        setSuggestions([]);
        var datafromLogin = location.state.logindetails.split(',');
        var projectid1 = datafromLogin[2];
        var propertyname1 = PropertyText;

        fetch(api + '/getpropertyid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ propertyname1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var propertyid1 = obj[0].propertyid;
                    propertyIDinFilters = propertyid1;

                    // get all locations sing officetypeid and fill locations in dropdown.
                    // Get type using propertyid
                    fetch(api + '/getcontroltypefromPropertyID1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ propertyid1 }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            //alert(data);
                            var obj1 = JSON.parse(data);
                            if (obj1.length > 0) {
                                if (obj1[0].type == "DropDown") {

                                    fetch(api + '/getpropertyvalues1', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({ projectid1, propertyid1 }),
                                    })
                                        .then(response => {
                                            return response.text();
                                        })
                                        .then(data => {
                                            var obj2 = JSON.parse(data);
                                            document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
                                            document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
                                            document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
                                            document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
                                            document.getElementById('cmbAdvancedfilters3').style.display = 'inline';
                                            document.getElementById('txtAdvancedFilters3').style.display = 'none';
                                            var ctldropdown = document.getElementById('cmbAdvancedfilters3');
                                            ctldropdown.length = 0;
                                            var option = document.createElement("option");
                                            option.value = "--Select Value--";
                                            option.text = "--Select Value--";
                                            ctldropdown.appendChild(option);
                                            for (var i = 0; i < obj2.length; i++) {
                                                option = document.createElement("option");
                                                option.value = obj2[i].propertyvalue;
                                                option.text = obj2[i].propertyvalue;
                                                ctldropdown.appendChild(option);
                                            }
                                        })
                                }
                                if (obj1[0].type == "Text") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'none';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'inline';
                                }
                                if (obj1[0].type == "Number") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'none';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'none';
                                }
                                if (obj1[0].type == "Date") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'none';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'none';
                                }
                            }
                        });
                }
            });
    }

    function onSelectedParentAssetType() {
        document.getElementById('progressDIV').style.display = 'inline';
        var select = document.getElementById('cmbParentAssetTypes');
        var selectedParentAssetType = select.options[select.selectedIndex].value;
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];

        // get assettype id by it is name.
        fetch(api + '/getparentassetIDbyName1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ selectedParentAssetType }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var assettypeID = obj[0].distributionnodetypeid;
                    fetch(api + '/getparentassets1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ assettypeID, projectid1, userid1, locationIDinAdavcendFIlters }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj1 = JSON.parse(data);
                            if (obj1.length > 0) {
                                var ctldropdown = document.getElementById('cmbParentAssets');
                                ctldropdown.length = 0;
                                var option = document.createElement("option");
                                option.value = "--Select Parent Asset--";
                                option.text = "--Select Parent Asset--";
                                ctldropdown.appendChild(option);
                                for (var i = 0; i < obj1.length; i++) {
                                    option = document.createElement("option");
                                    option.value = obj1[i].distnodename;
                                    option.text = obj1[i].distnodename;
                                    ctldropdown.appendChild(option);
                                }
                            }
                        });
                }
                document.getElementById('progressDIV').style.display = 'none';
            });
    }

    const onSelectedOfficeTypeDrawPolygon = (event) => {
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];
        var select = document.getElementById('cmbOfficetypesfiltersDrawPolygons');
        var selectedOfficetype = select.options[select.selectedIndex].value;
        var officetypename1 = selectedOfficetype;

        fetch(api + '/getofficetypeid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ officetypename1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {

                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var officetypeid1 = obj[0].officetypeid;

                    // get all locations sing officetypeid and fill locations in dropdown.
                    fetch(api + '/getlocations1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ projectid1, officetypeid1, userid1 }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj1 = JSON.parse(data);
                            setlocationsinDrawPolygon(obj1);
                        })
                }
            });
    }

    const onSelectedOfficeType = (event) => {
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];
        var select = document.getElementById('cmbOfficetypesfilters');
        var selectedOfficetype = select.options[select.selectedIndex].value;
        var officetypename1 = selectedOfficetype;
        fetch(api + '/getofficetypeid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ officetypename1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var officetypeid1 = obj[0].officetypeid;
                    fetch(api + '/getlocations1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ projectid1, officetypeid1, userid1 }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj1 = JSON.parse(data);
                            setlocations(obj1);
                        })
                }
            });
    }

    function cmbselectedPropertyinAdvFilters() {
        var datafromLogin = location.state.logindetails.split(',');
        var projectid1 = datafromLogin[2];
        var propertyname1 = document.getElementById('cmbPOIAdvancedfilters3').value;

        fetch(api + '/getpropertyid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ propertyname1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    var propertyid1 = obj[0].propertyid;
                    propertyIDinFilters = propertyid1;

                    // get all locations sing officetypeid and fill locations in dropdown.
                    fetch(api + '/getpropertyvalues1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ projectid1, propertyid1 }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj1 = JSON.parse(data);
                            if (obj1.length > 0) {
                                advancedFiltersType = obj1[0].typ;
                                if (obj1[0].typ == "DropDown") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'inline';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'none';
                                    var ctldropdown = document.getElementById('cmbAdvancedfilters3');
                                    ctldropdown.length = 0;
                                    var option = document.createElement("option");
                                    option.value = "--Select Value--";
                                    option.text = "--Select Value--";
                                    ctldropdown.appendChild(option);
                                    for (var i = 0; i < obj1.length; i++) {
                                        option = document.createElement("option");
                                        option.value = obj1[i].propertyvalue;
                                        option.text = obj1[i].propertyvalue;
                                        ctldropdown.appendChild(option);
                                    }
                                }
                                if (obj1[0].typ == "Text") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'none';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'inline';
                                }
                                if (obj1[0].typ == "Number") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'none';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'none';
                                }
                                if (obj1[0].typ == "Date") {
                                    document.getElementById('txtfromDateAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('txttoDateAdvancedFilters3').style.display = 'inline';
                                    document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
                                    document.getElementById('cmbAdvancedfilters3').style.display = 'none';
                                    document.getElementById('txtAdvancedFilters3').style.display = 'none';
                                }
                            }
                        })
                }
            });

    }

    function addIndicaterstoTable(indicatorName, datatype) {
        // get some default values here to use while adding data into the table.
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];
        var indicatorname1 = indicatorName;

        // Start adding values to table dynamically..
        var table = document.getElementById("FiltersTable");
        table.border = 0;
        var row;
        if (table.getElementsByTagName("tr").length == 0) {
            row = table.insertRow(0);
        }
        else {
            row = table.insertRow(table.getElementsByTagName("tr").length);
        }
        var cell1, cell2, cell3, cell4;
        if (datatype == "Text" || datatype == "DropDown") {
            cell1 = row.insertCell(0);
            cell2 = row.insertCell(1);
            cell3 = row.insertCell(2);
            cell2.setAttribute('colspan', 2);
        }
        else {
            cell1 = row.insertCell(0);
            cell2 = row.insertCell(1);
            cell3 = row.insertCell(2);
            cell4 = row.insertCell(3);
        }
        cell1.innerHTML = indicatorName;
        var fromdate, todate, fromtextbox, totextbox, ctldropdown;
        var dropdownvalue = 0;
        if (datatype == "Date") {
            fromdate = document.createElement('input');
            fromdate.type = "date";
            fromdate.id = "fromdate";
            fromdate.style.width = "100px";
            fromdate.style.height = "30px";
            fromdate.style.border = "1px solid #999";
            fromdate.style.backgroundColor = "white";
            fromdate.style.borderRadius = "5px";
            fromdate.style.boxShadow = "2px 2px #ccc";
            fromdate.style.fontSize = "12px";
            fromdate.style.color = '#343a40';
            fromdate.style.fontFamily = 'Georgia, Times New Roman, Times, serif';
            cell2.appendChild(fromdate);
            todate = document.createElement('input');
            todate.type = "date";
            todate.id = "todate";
            todate.style.width = "100px";
            todate.style.height = "30px";
            todate.style.border = "1px solid #999";
            todate.style.backgroundColor = "white";
            todate.style.borderRadius = "5px";
            todate.style.boxShadow = "2px 2px #ccc";
            todate.style.fontSize = "12px";
            todate.style.verticalAlign = 'middle';
            todate.style.textAlign = 'center';
            todate.style.padding = 0;
            todate.style.color = '#343a40';
            todate.style.fontFamily = 'Georgia, Times New Roman, Times, serif';
            cell3.appendChild(todate);
        }
        if (datatype == "Number") {
            fromtextbox = document.createElement('input');
            fromtextbox.type = "text";
            fromtextbox.id = "txtFrom";
            fromtextbox.value = 0;
            fromtextbox.style.width = "100px";
            fromtextbox.style.height = "30px";
            fromtextbox.style.border = "1px solid #999";
            fromtextbox.style.backgroundColor = "white";
            fromtextbox.style.borderRadius = "5px";
            fromtextbox.style.boxShadow = "2px 2px #ccc";
            fromtextbox.style.fontSize = "12px";
            fromtextbox.style.color = '#343a40';
            fromtextbox.style.fontFamily = 'Georgia, Times New Roman, Times, serif';
            cell2.appendChild(fromtextbox);
            totextbox = document.createElement('input');
            totextbox.type = "text";
            totextbox.id = "txtTo";
            totextbox.value = 0;
            totextbox.style.width = "100px";
            totextbox.style.height = "30px";
            totextbox.style.border = "1px solid #999";
            totextbox.style.backgroundColor = "white";
            totextbox.style.borderRadius = "5px";
            totextbox.style.boxShadow = "2px 2px #ccc";
            totextbox.style.fontSize = "12px";
            totextbox.style.color = '#343a40';
            totextbox.style.fontFamily = 'Georgia, Times New Roman, Times, serif';
            cell3.appendChild(totextbox);

        }
        if (datatype == "Text") {
            fromtextbox = document.createElement('input');
            fromtextbox.type = "text";
            fromtextbox.id = "txtFrom";
            fromtextbox.style.width = "100%";
            fromtextbox.style.height = "30px";
            fromtextbox.style.border = "1px solid #999";
            fromtextbox.style.backgroundColor = "white";
            fromtextbox.style.borderRadius = "5px";
            fromtextbox.style.boxShadow = "2px 2px #ccc";
            fromtextbox.style.fontSize = "12px";
            fromtextbox.style.color = '#343a40';
            fromtextbox.style.fontFamily = 'Georgia, Times New Roman, Times, serif';
            cell2.appendChild(fromtextbox);
        }
        if (datatype == "DropDown") {
            ctldropdown = document.createElement('select');
            ctldropdown.id = "ctldropdown";
            ctldropdown.style.width = "100%";
            ctldropdown.style.height = "30px";
            ctldropdown.style.border = "1px solid #999";
            ctldropdown.style.backgroundColor = "white";
            ctldropdown.style.borderRadius = "5px";
            ctldropdown.style.boxShadow = "2px 2px #ccc";
            ctldropdown.style.fontSize = "12px";
            ctldropdown.style.color = '#343a40';
            ctldropdown.style.fontFamily = 'Georgia, Times New Roman, Times, serif';
            cell2.appendChild(ctldropdown);

            // get the indicator id using indicator name
            ctldropdown.onchange = function () {
                var selectedPropertyName = ctldropdown.options[ctldropdown.selectedIndex].value;

                // get id for selected dropdown value
                fetch(api + '/getPropertIDbyName1', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ selectedPropertyName }),
                })
                    .then(response => {
                        return response.text();
                    })
                    .then(data => {
                        var obj = JSON.parse(data);
                        if (obj.length > 0) {
                            dropdownvalue = obj[0].indicatorpropertyvalueid;
                        }

                    });
            }

            fetch(api + '/getindicatorid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ indicatorname1 }),
            })
                .then(response => {
                    return response.text();
                })
                .then(data => {
                    var obj = JSON.parse(data);
                    if (obj.length > 0) {
                        var indicatorID = obj[0].indicatorid;
                        var indicatorid1 = indicatorID;

                        fetch(api + '/getindicatorproperties', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ indicatorid1, userid1, projectid1 }),
                        })
                            .then(response => {
                                return response.text();
                            })
                            .then(data => {
                                var obj1 = JSON.parse(data);
                                var option = document.createElement("option");
                                option.value = "--Select--";
                                option.text = "--Select--";
                                ctldropdown.appendChild(option);
                                for (var i = 0; i < obj1.length; i++) {
                                    option = document.createElement("option");
                                    option.value = obj1[i].propvalue;
                                    option.text = obj1[i].propvalue;
                                    ctldropdown.appendChild(option);
                                }
                            });
                    }
                });
        }
        var gobutton = document.createElement('input');
        gobutton.type = "button";
        gobutton.id = "btnGo";
        gobutton.value = 'GO';
        gobutton.className = "btn btn-primary rounded"
        gobutton.style.fontSize = "12px"

        gobutton.onclick = function () {
            document.getElementById('progressDIV').style.display = 'inline';
            var spatialObjects = [];
            var fromdatvalue = "''", todatevalue = "''", fromnumber = 0, tonumber = 0, singletextvalue = "''";
            var assetname = "''", assetcode = "''";
            let olid1 = 0;
            let globalolid1 = 0;
            var seelctedPOI1 = "''";
            let p_fromint = 0, p_toint = 0, p_fromdate = "''", p_todate = "''", p_value = "''", p_valueid = 0;
            let parentAssetIDFilters = 0;// parentAssetID;

            //get global location id
            if (document.getElementById('cmbLocationNames').value != '--Select Location--') {
                var locationname = document.getElementById('cmbLocationNames').value;

                fetch(api + '/getlocationid', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ locationname }),
                })
                    .then(response => {
                        return response.text();
                    })
                    .then(data => {
                        var obj = JSON.parse(data);
                        if (obj.length > 0) {
                            globalolid1 = obj[0].officelocationallocationid;
                        }
                    });
            }
            var cehckbox = document.getElementById('chkAdvancedFilter2');
            if (cehckbox.checked) {
                seelctedPOI1 = "'" + document.getElementById('txtPOIAdvancedfilters2').value + "'";
            }
            else {
                seelctedPOI1 = "''";
            }
            cehckbox = document.getElementById('chkAdvancedFilter1');
            if (cehckbox.checked) {
                assetcode = "'" + document.getElementById('txtAssetcodeAdvancedFilters1').value + "'";
                assetname = "'" + document.getElementById('txtAssetnameAdvancedFilters1').value + "'"; alert(assetname);
            }
            else {
                assetname = "''";
                assetcode = "''";
            }
            cehckbox = document.getElementById('chkAdvancedFilters3');
            if (cehckbox.checked) {
                // document.getElementById('cmbPOIAdvancedfilters3').disabled=false;
            }
            else {
                propertyIDinFilters = 0;
            }
            cehckbox = document.getElementById('chkAdvancedFilters3');
            if (cehckbox.checked) {
                p_value = "'" + document.getElementById('txtAdvancedFilters3').value + "'";
                p_fromint = document.getElementById('txtFromintegerAdvancedFilters3').value;
                p_toint = document.getElementById('txtTointegerAdvancedFilters3').value;
                p_fromdate = "'" + document.getElementById('txtfromDateAdvancedFilters3').value + "'";
                p_todate = "'" + document.getElementById('txttoDateAdvancedFilters3').value + "'";
            }
            else {
                p_fromint = 0;
                p_toint = 0;
                p_fromdate = "''";
                p_todate = "''";
                p_value = "''";
                p_valueid = 0;
            }
            if (datatype == "Date") {
                fromdatvalue = "'" + fromdate.value + "'";
                todatevalue = "'" + todate.value + "'";
                fromnumber = 0;
                tonumber = 0;
                singletextvalue = "''";
                dropdownvalue = 0;
            }
            else if (datatype == "Number") {
                fromdatvalue = "''";
                todatevalue = "''";
                fromnumber = fromtextbox.value;
                tonumber = totextbox.value;
                singletextvalue = "''";
                dropdownvalue = 0;
            }
            else if (datatype == "Text") {
                fromdatvalue = "''";
                todatevalue = "''";
                fromnumber = 0;
                tonumber = 0;
                singletextvalue = "'" + fromtextbox.value + "'";
                dropdownvalue = 0;
            }
            else if (datatype == "DropDown") {
                fromdatvalue = "''";
                todatevalue = "''";
                fromnumber = 0;
                tonumber = 0;
                singletextvalue = "''";
            }
            else {
                fromdatvalue = "''";
                todatevalue = "''";
                fromnumber = 0;
                tonumber = 0;
                singletextvalue = "''";
                dropdownvalue = 0;
            }

            // Execute final query...
            var assettypeswithcommaseperated = "''";
            var insideflag = false;
            for (var i = 0; i < assettypesArray.length; i++) {
                insideflag = true;
                if (i == 0) {
                    assettypeswithcommaseperated = assettypesArray[i];
                }
                else {
                    assettypeswithcommaseperated = assettypeswithcommaseperated + "," + assettypesArray[i];
                }
            }
            if (insideflag == true) {
                assettypeswithcommaseperated = "'" + assettypeswithcommaseperated + "'";
            }
            var selectedLocation = document.getElementById('cmlocationsfilters').value;
            fetch(api + '/getlocationid1', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ selectedLocation }),
            })
                .then(response => {
                    return response.text();
                })
                .then(data => {
                    var obj = JSON.parse(data);
                    if (obj.length > 0) {
                        olid1 = obj[0].officelocationallocationid;
                    }
                    else {
                        olid1 = 0;
                    }
                    var parentAsset = '';
                    var select = document.getElementById('cmbParentAssets');
                    if (select.options.length == 0) {
                        parentAsset = '';
                    }
                    else {
                        parentAsset = select.options[select.selectedIndex].value;
                    }

                    fetch(api + '/getParentAssetIDbyNameFilters1', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ parentAsset }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj = JSON.parse(data);
                            if (obj.length > 0) {
                                parentAssetIDFilters = obj[0].distributionnodeid;
                            }
                            var propertyValue = '';
                            select = document.getElementById('cmbAdvancedfilters3');
                            if (select.options.length == 0) {
                                propertyValue = '';
                            }
                            else {
                                propertyValue = select.options[select.selectedIndex].value;
                            }

                            fetch(api + '/getPropertyValueID1', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ propertyValue }),
                            })
                                .then(response => {
                                    return response.text();
                                })
                                .then(data => {
                                    var obj = JSON.parse(data);
                                    if (obj.length > 0) {

                                        p_valueid = obj[0].propertyvalueid;
                                    }

                                    // From here get indicator id and proceed.

                                    fetch(api + '/getindicatorid', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({ indicatorname1 }),
                                    })
                                        .then(response => {
                                            return response.text();
                                        })
                                        .then(data => {
                                            var obj = JSON.parse(data);
                                            if (obj.length > 0) {

                                                var indicatorID = obj[0].indicatorid;
                                                var indicatorid1 = indicatorID;
                                                console.log(projectid1 + "," + indicatorid1 + "," + olid1 + "," + globalolid1 + "," + assettypeswithcommaseperated + "," + seelctedPOI1 + "," + fromnumber + "," + tonumber + "," + fromdatvalue + "," + todatevalue + "," + singletextvalue + "," + dropdownvalue + "," + assetname + "," + assetcode + "," + propertyIDinFilters + "," + p_fromint + "," + p_toint + "," + p_fromdate + "," + p_todate + "," + p_value + "," + p_valueid + "," + parentAssetIDFilters);

                                                fetch(api + '/getnodes', {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                    },

                                                    body: JSON.stringify({ projectid1, indicatorid1, olid1, globalolid1, assettypeswithcommaseperated, seelctedPOI1, fromnumber, tonumber, fromdatvalue, todatevalue, singletextvalue, dropdownvalue, assetname, assetcode, propertyIDinFilters, p_fromint, p_toint, p_fromdate, p_todate, p_value, p_valueid, parentAssetIDFilters }),
                                                })
                                                    .then(response => {
                                                        return response.text();
                                                    })
                                                    .then(data => {
                                                        var obj1 = JSON.parse(data);
                                                        assetsSourceforArrowFunction.clear();
                                                        connectivitySourceforArrowFunction.clear();
                                                        if (obj1.length == 0) {
                                                            alert("No record(s) found.");
                                                            document.getElementById('progressDIV').style.display = 'none';
                                                            return;
                                                        }

                                                        // Read longitude and latitude and display assets on map
                                                        // For adding assets
                                                        document.getElementById("tblBottomTable").innerHTML = "";
                                                        document.getElementById('lblTotalRecordinBottomTable').innerHTML = '';
                                                        document.getElementById('lblTotalRecordinBottomTable').style.margin = '1em 0px 0px'
                                                        document.getElementById('indicatorName').innerHTML = '-' + indicatorName + '-';
                                                        document.getElementById('selectedCategory').innerHTML = document.getElementById('cmbFiltersCategory').value;
                                                        for (var i = 0; i < obj1.length; i++) {
                                                            var marker = new Feature({
                                                                geometry: new Point(
                                                                    [obj1[i].longitude, obj1[i].latitude]
                                                                ),
                                                            });
                                                            setSymbols(obj1[i].icon, marker);
                                                            if (document.getElementById('chkSPatilQueryFilters').checked) {
                                                                if (spatialQuerySourceOut.getFeatures().length > 0) {    // Newly drawn boundary
                                                                    var turfpoint = turf.point([parseFloat(obj1[i].longitude), parseFloat(obj1[i].latitude)]);
                                                                    var format = new GeoJSON();
                                                                    var wktRepresenation = format.writeGeometryObject(spatialQuerySourceOut.getFeatures()[0].getGeometry());
                                                                    var isInside1 = turf.inside(turfpoint, wktRepresenation);
                                                                    if (isInside1 && document.getElementById('chkInsideBoundary').checked == true) {
                                                                        assetsSourceforArrowFunction.addFeature(marker);
                                                                        marker.set('Distribution Node ID', obj1[i].distnodeid);
                                                                        marker.set('assetid', obj1[i].distnodeid);
                                                                        marker.set('indicatorid', indicatorid1);
                                                                        marker.set('Node Name', obj1[i].distnodename);
                                                                        marker.set('Node Code', obj1[i].distnodecode);
                                                                        spatialObjects.push(obj1[i]);
                                                                    }
                                                                    if (isInside1 == false && document.getElementById('chkInsideBoundary').checked == false) {
                                                                        assetsSourceforArrowFunction.addFeature(marker);
                                                                        marker.set('Distribution Node ID', obj1[i].distnodeid);
                                                                        marker.set('assetid', obj1[i].distnodeid);
                                                                        marker.set('indicatorid', indicatorid1);
                                                                        marker.set('Node Name', obj1[i].distnodename);
                                                                        marker.set('Node Code', obj1[i].distnodecode);
                                                                        spatialObjects.push(obj1[i]);
                                                                    }
                                                                }
                                                                else {
                                                                    if (drawPolgonSourceArrowfunction.getFeatures().length > 0) {
                                                                        var turfpoint = turf.point([parseFloat(obj1[i].longitude), parseFloat(obj1[i].latitude)]);
                                                                        var format = new GeoJSON();
                                                                        var wktRepresenation = format.writeGeometryObject(drawPolgonSourceArrowfunction.getFeatures()[0].getGeometry());
                                                                        var isInside1 = turf.inside(turfpoint, wktRepresenation);
                                                                        if (isInside1 && document.getElementById('chkInsideBoundary').checked == true) {
                                                                            assetsSourceforArrowFunction.addFeature(marker);
                                                                            marker.set('Distribution Node ID', obj1[i].distnodeid);
                                                                            marker.set('assetid', obj1[i].distnodeid);
                                                                            marker.set('indicatorid', indicatorid1);
                                                                            marker.set('Node Name', obj1[i].distnodename);
                                                                            marker.set('Node Code', obj1[i].distnodecode);
                                                                            spatialObjects.push(obj1[i]);
                                                                        }
                                                                        if (isInside1 == false && document.getElementById('chkInsideBoundary').checked == false) {
                                                                            assetsSourceforArrowFunction.addFeature(marker);
                                                                            marker.set('Distribution Node ID', obj1[i].distnodeid);
                                                                            marker.set('assetid', obj1[i].distnodeid);
                                                                            marker.set('indicatorid', indicatorid1);
                                                                            marker.set('Node Name', obj1[i].distnodename);
                                                                            marker.set('Node Code', obj1[i].distnodecode);
                                                                            spatialObjects.push(obj1[i]);
                                                                        }
                                                                    }
                                                                    else {
                                                                        assetsSourceforArrowFunction.addFeature(marker);
                                                                        marker.set('Distribution Node ID', obj1[i].distnodeid);
                                                                        marker.set('assetid', obj1[i].distnodeid);
                                                                        marker.set('indicatorid', indicatorid1);
                                                                        marker.set('Node Name', obj1[i].distnodename);
                                                                        marker.set('Node Code', obj1[i].distnodecode);
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                assetsSourceforArrowFunction.addFeature(marker);
                                                                marker.set('Distribution Node ID', obj1[i].distnodeid);
                                                                marker.set('assetid', obj1[i].distnodeid);
                                                                marker.set('indicatorid', indicatorid1);
                                                                marker.set('Node Name', obj1[i].distnodename);
                                                                marker.set('Node Code', obj1[i].distnodecode);
                                                            }
                                                        }
                                                        if (obj1.length > 0) {
                                                            var extent = assetsSourceforArrowFunction.getExtent();
                                                            mapforArrowFunction.getView().fit(extent);
                                                            mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() - 1);

                                                            if (spatialObjects.length > 0) {
                                                                addDatatoBottomTable(spatialObjects);
                                                            }
                                                            else {
                                                                addDatatoBottomTable(obj1);
                                                            }
                                                        }
                                                    });
                                            }
                                        })
                                });
                        });

                });
            document.getElementById('filtersDIV').style.display = 'none';
            document.getElementById('progressDIV').style.display = 'none';
        }
        if (datatype == "Text" || datatype == "DropDown") {
            cell3.appendChild(gobutton);
        }
        else {
            cell4.appendChild(gobutton);
        }
    }

    function setSymbols(iconName, marker) {

        if (iconName == "2364.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: asset2364,
                        scale: 0.4
                    })
                })
            );
        }
        if (iconName == "2363.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: asset2363,
                        scale: 0.4
                    })
                })
            );
        }
        if (iconName == "2362.png") {

            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: asset2362,
                        scale: 0.4
                    })
                })
            );
        }
        if (iconName == "2411.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: asset2411,
                        scale: 0.4
                    })
                })
            );
        }
        if (iconName == "MCO.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MCO,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Approved (2).png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Approved,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Black Spots.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: BlackSpots,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "CI Approved.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: CIApproved,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "CI Done.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: CIDone,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "CI Pending.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: CIPending,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "CI Rejected.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: CIRejected,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Field installed.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Fieldinstalled,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "intalled Meter.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: intalledMeter,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Meter cummunication analysis.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Metercummunicationanalysis,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MI Approved.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MIApproved,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MI Date.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MIDate,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MI Done.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MIDone,
                        scale: 0.07
                    })
                })
            );
        }

        if (iconName == "MI Pending.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MIPending,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MI Rejected.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MIRejected,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MSO Closed.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MSOClosed,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MSO Issued.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MSOIssued,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MSO Work In Progress.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MSOWorkInProgress,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Non Communicating Meter (2).png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: NonCommunicatingMeter,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Provisioned HES MDM.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: ProvisionedHESMDM,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Read fails.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Readfails,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Rejected.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Rejected,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Service request type.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Servicerequesttype,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Specific alarm.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Specificalarm,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "Specific defect.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: Specificdefect,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "BLACKSPOT.jpeg") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: BLACKSPOT,
                        scale: 0.07
                    })
                })

            );
        }
        if (iconName == "CI UNAPPROVED.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: CIUNAPPROVED,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "MI UNAPPROVED.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: MIUNAPPROVED,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "NON COMMUNICATING PREMISES.png") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: NONCOMMUNICATINGPREMISES,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "OLD METER RETURNED.jpeg") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: OLDMETERRETURNED,
                        scale: 0.07
                    })
                })
            );
        }
        if (iconName == "PENDING MSO.jpeg") {
            marker.setStyle(
                new Style({
                    image: new Icon({

                        src: PENDINGMSO,
                        scale: 0.07
                    })
                })
            );
        }
    }

    function onselectedLocation() {
        document.getElementById('progressDIV').style.display = 'inline';
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var projectid1 = datafromLogin[2];
        document.getElementById("tblBottomTable").innerHTML = "";
        document.getElementById('lblTotalRecordinBottomTable').innerHTML = '';
        document.getElementById("TreeviewTable").innerHTML = "";
        var select = document.getElementById('cmbLocationNames');
        var selectedLocation = select.options[select.selectedIndex].value;
        var locationID = 0;
        var locationname = selectedLocation;
        fetch(api + '/getlocationid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ locationname }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    locationID = obj[0].officelocationallocationid;
                    globalLocationID = locationID;

                    fetch(api + '/getassets', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ projectid1, userid1, locationID }),
                    })
                        .then(response => {
                            return response.text();
                        })
                        .then(data => {
                            var obj = JSON.parse(data);
                            assetsSourceforArrowFunction.clear();
                            markerTypesArray.length = 0;
                            if (obj.length == 0) {
                                alert("No record(s) found.");
                                document.getElementById('progressDIV').style.display = 'none';
                                return;
                            }
                            // For adding assets
                            for (var i = 0; i < obj.length; i++) {
                                var marker = new Feature({
                                    geometry: new Point(
                                        [obj[i].longitude, obj[i].latitude]
                                    ),
                                });
                                setSymbols(obj[i].icon, marker);
                                marker.set('Distribution Node ID', obj[i].distributionnodeid);
                                marker.set('Distribution Node Type Name', obj[i].distributionnodetypename);
                                marker.set('Span', obj[i].span);
                                marker.set('Icon', obj[i].icon);
                                marker.set('assetid', obj[i].distributionnodeid);
                                marker.set('indicatorid', 0);
                                marker.set('Node Name', obj[i].distnodename);
                                marker.set('Node Code', obj[i].distnodecode);
                                assetsSourceforArrowFunction.addFeature(marker);
                                // Add feature types to array
                                if (!markerTypesArray.includes(obj[i].distributionnodetypename)) {
                                    // Add asset types to treeview from array
                                    markerTypesArray.push(obj[i].distributionnodetypename);
                                    AddAssetstoTreeview(assetsSourceforArrowFunction, obj[i].distributionnodetypename)
                                }
                            }
                            connectivitySourceforArrowFunction.clear();
                            for (var i = 0; i < obj.length; i++) {
                                var connectedLine = null;
                                var connectedPoint = null;
                                if (obj[i].circuit != null) {
                                    var circuitJson = JSON.parse(obj[i].circuit);
                                    var linegeometry = null;
                                    for (var j = 0; j < circuitJson.list.length; j++) {
                                        if (j == 0) {
                                            var points = [
                                                [obj[i].fromlong, obj[i].fromlat], [obj[i].longitude, obj[i].latitude]
                                            ];
                                            connectedLine = new Feature({
                                                geometry: new LineString(
                                                    points
                                                ),
                                            });
                                            connectedLine.set('Idle Line', circuitJson.list[j].voltage.idleLine);
                                            connectedLine.set('Color Code', circuitJson.list[j].voltage.colorCode);
                                            connectedLine.set('Span', circuitJson.span);
                                            connectedLine.setStyle(connectivityStyleFun);
                                            connectivitySourceforArrowFunction.addFeature(connectedLine);
                                            linegeometry = connectedLine;
                                        }
                                        else {
                                            var turfline = turf.lineString([[parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[0]), parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[1])], [parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[2]), parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[3])]], { "stroke": "#F00" });
                                            var offsetLine = turf.lineOffset(turfline, 0.3, { units: 'meters' });
                                            var coor1Long = turf.getCoords(offsetLine).toString().split(',')[0];
                                            var coor1Lat = turf.getCoords(offsetLine).toString().split(',')[1];
                                            var coor2Long = turf.getCoords(offsetLine).toString().split(',')[2];
                                            var coor2Lat = turf.getCoords(offsetLine).toString().split(',')[3];
                                            var points = [
                                                [coor1Long, coor1Lat], [coor2Long, coor2Lat]
                                            ];
                                            connectedLine = new Feature({
                                                geometry: new LineString(
                                                    points
                                                ),
                                            });
                                            connectedLine.set('Idle Line', circuitJson.list[j].voltage.idleLine);
                                            connectedLine.set('Color Code', circuitJson.list[j].voltage.colorCode);
                                            connectedLine.set('Span', circuitJson.span);
                                            connectedLine.setStyle(connectivityStyleFun);
                                            connectivitySourceforArrowFunction.addFeature(connectedLine);
                                            linegeometry = connectedLine;
                                        }
                                    }
                                }
                                else {
                                    var points = [
                                        [obj[i].fromlong, obj[i].fromlat], [obj[i].longitude, obj[i].latitude]
                                    ];
                                    connectedLine = new Feature({
                                        geometry: new LineString(
                                            points
                                        ),
                                    });
                                    connectedLine.set('Idle Line', false);
                                    connectedLine.set('Color Code', 'red');
                                    connectedLine.set('Span', 0);
                                    connectedLine.setStyle(connectivityStyleFun);
                                    connectivitySourceforArrowFunction.addFeature(connectedLine);
                                }
                            }
                            if (obj.length > 0) {
                                var extent = assetsSourceforArrowFunction.getExtent();
                                mapforArrowFunction.getView().fit(extent);
                                mapforArrowFunction.getView().setCenter([extent[2], extent[3]])
                                mapforArrowFunction.getView().setZoom(10);
                                addDatatoBottomTable(obj);
                            }
                            document.getElementById('progressDIV').style.display = 'none';
                        });
                    // End
                }
            });
    }

    function addDatatoBottomTable(jsonObject) {
        var data = []
        var headers = []
        jsonObject.forEach(e => {
            const { icon, projid, ...newObject } = e;
            data.push(newObject)
        })
        Object.keys(jsonObject[0]).forEach((key) => {
            if (key != 'icon' && key != 'projid' && key != 'circuit' && key != 'fromlat' && key != 'fromlong') {
                var title = ''
                if (key == "distnodeid")
                    title = "ID";
                if (key == "distnodename")
                    title = "Name";
                if (key == "distnodecode")
                    title = "Code";
                if (key == "latitude")
                    title = "Latitude";
                if (key == "longitude")
                    title = "Longitude";
                if (key == "distnodetypename")
                    title = "Type";
                if (key == "val")
                    title = "Val";
                if (key == "distributionnodetypename")
                    title = "Asset Type";
                if (key == "distributionnodeid")
                    title = "ID";
                if (key == "distributionnodename")
                    title = "Name";
                if (key == "followDistributionnodeid")
                    title = "Parent ID";
                headers.push({ data: key, title: title })
            }
        });
        setTableData({ header: headers, data: data })
        let tabRef = $('#tblBottomTable')
        if ($.fn.DataTable.isDataTable(tabRef)) {
            tabRef.DataTable().destroy();
        }
        var table = new DataTables(tabRef[0], {
            data: data,
            columns: headers,
            dom: 'lrtip',
            info: false,
        });
        // Add a search input to each column header
        table.columns().every(function () {
            var column = this;
            var header = $(column.header());

            // Append a search input to the column header
            header.append('<br><input type="text" placeholder="Search ' + header.text() + '">');

            // Apply the search on keyup
            $('input', header).on('keyup change clear', function () {
                if (column.search() !== this.value) {
                    column.search(this.value).draw();
                }
            });

            // Stop event propagation on click
            $('input', header).on('click', function (e) {
                e.stopPropagation();
            });
        });
        document.getElementById('lblTotalRecordinBottomTable').innerHTML = "Total no.of records: " + jsonObject.length;
        document.getElementById('bottomTableDIV').style.display = 'block';
    }

    function closebottomTableDIV() {
        document.getElementById('bottomTableDIV').style.display = 'none';
    }

    const createWorksheet = (columns, rowData) => {
        return new Promise((resolve, reject) => { // on resolve return the sheet
            const worksheet = utils.aoa_to_sheet([columns, ...rowData]); // creat a sheet 
            resolve(worksheet); // 
        });
    };

    const createWorkbookAndWriteFile = async (columns, rowData) => {
        const sheet = await createWorksheet(columns, rowData); // load the created sheet(only after its creation, hence the need of async await)
        const workBook = utils.book_new(); // creat work book
        utils.book_append_sheet(workBook, sheet, 'assets_data'); //append sheet to workbook
        writeFile(workBook, 'assets_data.xlsx') //download excel
    };

    function DownloadbottomTableDIV() {
        let columns = []
        let rowData = Array(tableData.data.length).fill().map(() => []);
        tableData.header.forEach((e, i) => {
            columns.push(e.title) // prepare column defination dynamically
            tableData.data.forEach((data, ind) => {
                rowData[ind][i] = data[e.data] // prepare row data on the basis of column defination dynamically
            })
        })
        createWorkbookAndWriteFile(columns, rowData) //creat and download table in .xlsx formate
    }

    function connectivityStyleFun(feature) {
        // Line style
        if (feature.get('Idle Line') == true) {
            return new Style({
                stroke: new Stroke({
                    color: feature.get('Color Code'),
                    width: 2,
                    lineDash: [5, 5]
                }),
            });
        }
        else {
            return new Style({
                stroke: new Stroke({
                    color: feature.get('Color Code'),
                    width: 2
                }),
                text: new Text({
                    textBaseline: 'middle',
                    font: 'bold 16px montserrat',
                    text: feature.get('Span').toString(),
                    fill: new Fill({ color: 'red' }),
                    stroke: new Stroke({ color: 'white', width: 3 }),
                    offsetX: 0,
                    offsetY: 0,
                    placement: 'line',
                    maxAngle: 45,
                    overflow: false,
                    rotation: 0,
                    rotateWithView: true
                }),
            });
        }
        // Adding text and style
    }

    // Points
    function labelStyleFunction(feature, resolution) {
        return new Style({
            text: new Text({
                text: document.getElementById('txtMaptitle').value.toString(),
                fill: new Fill({
                    color: "#aa3300"
                }),
                stroke: new Stroke({
                    color: "#ffffff",
                    width: 5
                }),
                scale: 2.5
            })
        });
    }

    function AddAssetstoTreeview(sourcelayer1, name) {
        var table = document.getElementById("TreeviewTable");
        table.border = 0;
        var row;

        if (table.getElementsByTagName("tr").length == 0) {
            row = table.insertRow(0);
        }
        else {
            row = table.insertRow(table.getElementsByTagName("tr").length);
        }
        var cell1 = row.insertCell(0);
        var cell2 = row.insertCell(1);
        var chkbox = document.createElement('input');
        chkbox.type = "checkbox";
        chkbox.id = "chk";
        chkbox.name = "chk";
        chkbox.checked = true;
        chkbox.onchange = function () {
            if (chkbox.checked) {
                sourcelayer1.forEachFeature(function (feature) {
                    if (feature.get('Distribution Node Type Name') == name) {
                        setSymbols(feature.get('Icon'), feature);
                    }
                });
            }
            else {
                sourcelayer1.forEachFeature(function (feature) {
                    if (feature.get('Distribution Node Type Name') == name) {
                        feature.setStyle(new Style({}));
                    }
                });
            }
        }
        cell1.appendChild(chkbox);
        cell2.innerHTML = name;
    }

    const addTitletoMap = () => {
        // Add text to center  of map 
        printTextSourceforArrowFunction.clear();
        var extent = mapforArrowFunction.getView().calculateExtent(mapforArrowFunction.getSize());
        var splitExtent = extent.toString().split(',');
        var x1 = splitExtent[0];
        var y1 = splitExtent[1];
        var x2 = splitExtent[2];
        var y2 = splitExtent[3];
        var mapcentercoorX = (parseFloat(x1) + parseFloat(x2)) / 2;
        var connectedPoint = new Feature({
            geometry: new Point(
                [mapcentercoorX, y2]
            ),
        });
        connectedPoint.setStyle(labelStyleFunction);
        printTextSourceforArrowFunction.addFeature(connectedPoint);
    }

    const clearMapTitle = () => {
        printTextSourceforArrowFunction.clear();
        document.getElementById('txtMaptitle').value = '';
    }

    const onchangeAdvancedFilters1 = () => {
        var cehckbox = document.getElementById('chkAdvancedFilter1');
        if (cehckbox.checked) {
            document.getElementById('txtAssetcodeAdvancedFilters1').disabled = false;
            document.getElementById('txtAssetnameAdvancedFilters1').disabled = false;
        }
        else {
            document.getElementById('txtAssetcodeAdvancedFilters1').disabled = true;
            document.getElementById('txtAssetnameAdvancedFilters1').disabled = true;
            document.getElementById('txtAssetcodeAdvancedFilters1').value = '';
            document.getElementById('txtAssetnameAdvancedFilters1').value = '';
        }
    }

    const onchangechckSpatialQueryFilters = () => {
        var checkbox = document.getElementById('chkSPatilQueryFilters');
        if (checkbox.checked) {
            document.getElementById('btnDrawpolygonFiltersSpatialQuery').style.display = 'inline';
            document.getElementById('btnClearSparialQueryPolygons').style.display = 'inline';
            document.getElementById('lblInsideBoundary').style.display = 'inline';
            document.getElementById('chkInsideBoundary').style.display = 'inline';
        }
        else {
            document.getElementById('btnDrawpolygonFiltersSpatialQuery').style.display = 'none';
            document.getElementById('btnClearSparialQueryPolygons').style.display = 'none';
            document.getElementById('lblInsideBoundary').style.display = 'none';
            document.getElementById('chkInsideBoundary').style.display = 'none';
            mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
        }
    }

    const onDrawpolygonSPatialQuery = () => {
        mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
        spatialQuerySourceOut.clear();

        drawinteraction = new Draw({
            source: spatialQuerySourceOut,
            type: 'Polygon',
        });
        mapforArrowFunction.addInteraction(drawinteraction);
        setDrawINteraction(drawinteraction);
    }

    function onclearSpatialQueryPolygons() {
        mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
        spatialQuerySourceOut.clear();
    }

    const onchangeAdvancedFilters2 = () => {
        var cehckbox = document.getElementById('chkAdvancedFilter2');
        if (cehckbox.checked) {
            document.getElementById('txtPOIAdvancedfilters2').disabled = false;
        }
        else {

            document.getElementById('txtPOIAdvancedfilters2').disabled = true;
            document.getElementById('txtPOIAdvancedfilters2').value = '';
        }
    }

    const onchangeAdvancedFilters3 = () => {
        var cehckbox = document.getElementById('chkAdvancedFilters3');
        if (cehckbox.checked) {
            document.getElementById('cmbPOIAdvancedfilters3').disabled = false;
        }
        else {
            document.getElementById('cmbPOIAdvancedfilters3').disabled = true;
            document.getElementById('cmbPOIAdvancedfilters3').value = '';
            document.getElementById('txtfromDateAdvancedFilters3').value = '';
            document.getElementById('txttoDateAdvancedFilters3').value = '';
            document.getElementById('txtFromintegerAdvancedFilters3').value = '';
            document.getElementById('txtTointegerAdvancedFilters3').value = '';
            document.getElementById('txtAdvancedFilters3').value = '';
            var ctldropdown = document.getElementById('cmbAdvancedfilters3');
            ctldropdown.length = 0;
            document.getElementById('txtfromDateAdvancedFilters3').style.display = 'none';
            document.getElementById('txttoDateAdvancedFilters3').style.display = 'none';
            document.getElementById('txtFromintegerAdvancedFilters3').style.display = 'none';
            document.getElementById('txtTointegerAdvancedFilters3').style.display = 'none';
            document.getElementById('cmbAdvancedfilters3').style.display = 'none';
            document.getElementById('txtAdvancedFilters3').style.display = 'none';
        }
    }

    function onchangeAdvancedFilters3property() {
        var select = document.getElementById('cmbAdvancedfilters3');
        var propertyValue = select.options[select.selectedIndex].value;

        fetch(api + '/getPropertyValueID1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ propertyValue }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {

                    p_valueidGLobal = obj[0].propertyvalueid;
                }
            });
    }

    function onChangeParentAssets() {
        var select = document.getElementById('cmbParentAssets');
        var parentAsset = select.options[select.selectedIndex].value;
        parentAssetID = 0;

        fetch(api + '/getParentAssetIDbyNameFilters1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ parentAsset }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                if (obj.length > 0) {
                    parentAssetID = obj[0].distributionnodeid;
                }
            });
    }

    function addInteractioninDrawingPolygon() {
        mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
        drawPolgonSourceArrowfunction.clear();
        var checkbox = document.getElementById('chkFreehanddrawing');
        if (checkbox.checked) {
            drawinteraction = new Draw({
                source: drawPolgonSourceArrowfunction,
                type: 'Polygon',
                freehand: true,
            });
        }
        else {
            drawinteraction = new Draw({
                source: drawPolgonSourceArrowfunction,
                type: 'Polygon',
            });
        }
        mapforArrowFunction.addInteraction(drawinteraction);
        setDrawINteraction(drawinteraction);
        const modify = new Modify({ source: drawPolgonSourceArrowfunction });
        mapforArrowFunction.addInteraction(modify);
        const snap = new Snap({ source: drawPolgonSourceArrowfunction });
        mapforArrowFunction.addInteraction(snap);
    }

    const oncollapseleftpanel = () => {
        document.getElementById('accordionSidebar').style.position = "absolute";
        document.getElementById('accordionSidebar').style.width = "10%";
    }

    function selectInteratiom() {
        mapforArrowFunction.removeInteraction(drawinteractionArrowFunction);
    }

    function removeFeaure() {
        drawPolgonSourceArrowfunction.clear();
    }

    function exportDrawingFeatures() {
        var json = new GeoJSON().writeFeatures(drawPolgonSourceArrowfunction.getFeatures());
        downloadgeoJson(json, 'polygons.geojson', 'text/plain');
    }

    function SaveBoundary() {
        var select = document.getElementById('cmbOfficetypesfiltersDrawPolygons');
        var officename1 = select.options[select.selectedIndex].value;
        var locationname1 = document.getElementById('cmlocationsfiltersDrawPolygons').value;
        var locationid1 = locationIDinAdavcendFIlters;

        // delete existing boundary and add new boundary
        fetch(api + '/deleteexistingBoundary1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ locationid1 }),
        })
            .then(response => {
                return response.text();
            })
        var format = new WKT();
        var wktRepresenation = format.writeGeometry(drawPolgonSourceArrowfunction.getFeatures()[0].getGeometry());
        var coordinates = wktRepresenation;
        fetch(api + '/updateboundary1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ officename1, locationname1, locationid1, coordinates }),
        })
            .then(response => {
                return response.text();
            })
    }

    function downloadgeoJson(content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();

    }

    function creatAndSaveShapeFile(featuredList, type) {
        var json = new GeoJSON().writeFeatures(featuredList);
        let geoJson = JSON.parse(json)
        const rows = geoJson.features.map(feature => feature.properties);
        const geometry_type = 'POINT';
        const geometries = geoJson.features.map(feature => {
            const coordinates = feature.geometry.coordinates;
            return coordinates
        });
        shpwrt.write(rows, geometry_type, geometries, function (err, files) {
            if (err) throw err;
            const zip = new jszip();
            zip.file(type + '.shp', files.shp.buffer);
            zip.file(type + '.shx', files.shx.buffer);
            zip.file(type + '.dbf', files.dbf.buffer);
            zip.file(type + '.prj', files.prj);
            zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, type + '.zip');
            });
        });
    }

    function exportData() {
        var select = document.getElementById('cmbDataFormats');
        console.log(assetsSourceforArrowFunction.getFeatures());
        if (select.selectedIndex == 0) {
            var json = new GeoJSON().writeFeatures(assetsSourceforArrowFunction.getFeatures());
            downloadgeoJson(json, 'assets.geojson', 'text/plain');
            var json1 = new GeoJSON().writeFeatures(connectivitySourceforArrowFunction.getFeatures());
            downloadgeoJson(json1, 'connectivity.geojson', 'text/plain');
        }
        if (select.selectedIndex == 1) {
            var json = new KML().writeFeatures(assetsSourceforArrowFunction.getFeatures());
            downloadgeoJson(json, 'assets.kml', 'text/plain');
            var json1 = new KML().writeFeatures(connectivitySourceforArrowFunction.getFeatures());
            downloadgeoJson(json1, 'connectivity.kml', 'text/plain');
        }
        if (select.selectedIndex == 2) {
            creatAndSaveShapeFile(assetsSourceforArrowFunction.getFeatures(), 'asset')
        }
    }

    function handleClickEventTableData() {
        document.getElementById('bottomTableDIV').style.display = 'inline';
    }

    function showConnectivityforSelectedAsset() {
        var datafromLogin = location.state.logindetails.split(',');
        var userid1 = datafromLogin[0];
        var distnodeid1 = selectedFeatureDistributionNodeID;

        fetch(api + '/getconnectivityassets1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ distnodeid1, userid1 }),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                var obj = JSON.parse(data);
                connectivitySourceforArrowFunction.clear();
                for (var i = 0; i < obj.length; i++) {
                    var connectedLine = null;
                    var connectedPoint = null;
                    if (obj[i].circuit != null) {
                        var circuitJson = JSON.parse(obj[i].circuit);
                        var linegeometry = null;
                        for (var j = 0; j < circuitJson.list.length; j++) {
                            if (j == 0) {
                                var points = [
                                    [obj[i].fromlong, obj[i].fromlat], [obj[i].longitude, obj[i].latitude]
                                ];
                                connectedLine = new Feature({
                                    geometry: new LineString(
                                        points
                                    ),
                                });
                                connectedLine.set('Idle Line', circuitJson.list[j].voltage.idleLine);
                                connectedLine.set('Color Code', circuitJson.list[j].voltage.colorCode);
                                connectedLine.set('Span', circuitJson.span);
                                connectedLine.setStyle(connectivityStyleFun);
                                connectivitySourceforArrowFunction.addFeature(connectedLine);
                                linegeometry = connectedLine;
                            }
                            else {
                                var turfline = turf.lineString([[parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[0]), parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[1])], [parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[2]), parseFloat(linegeometry.getGeometry().getCoordinates().toString().split(',')[3])]], { "stroke": "#F00" });
                                var offsetLine = turf.lineOffset(turfline, 0.3, { units: 'meters' });
                                var coor1Long = turf.getCoords(offsetLine).toString().split(',')[0];
                                var coor1Lat = turf.getCoords(offsetLine).toString().split(',')[1];
                                var coor2Long = turf.getCoords(offsetLine).toString().split(',')[2];
                                var coor2Lat = turf.getCoords(offsetLine).toString().split(',')[3];
                                var points = [
                                    [coor1Long, coor1Lat], [coor2Long, coor2Lat]
                                ];
                                connectedLine = new Feature({
                                    geometry: new LineString(
                                        points
                                    ),

                                });
                                connectedLine.set('Idle Line', circuitJson.list[j].voltage.idleLine);
                                connectedLine.set('Color Code', circuitJson.list[j].voltage.colorCode);
                                connectedLine.set('Span', circuitJson.span);
                                connectedLine.setStyle(connectivityStyleFun);
                                connectivitySourceforArrowFunction.addFeature(connectedLine);
                                linegeometry = connectedLine;
                            }
                        }
                    }
                    else {
                        var points = [
                            [obj[i].fromlong, obj[i].fromlat], [obj[i].longitude, obj[i].latitude]
                        ];
                        connectedLine = new Feature({
                            geometry: new LineString(
                                points
                            ),
                        });
                        connectedLine.set('Idle Line', false);
                        connectedLine.set('Color Code', 'red');
                        connectedLine.set('Span', 0);
                        connectedLine.setStyle(connectivityStyleFun);
                        connectivitySourceforArrowFunction.addFeature(connectedLine);
                    }
                }
            });
    }

    const printDocument = () => {
        const mapCanvas = document.createElement('canvas');
        const size = mapforArrowFunction.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
            mapforArrowFunction.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
            function (canvas) {
                if (canvas.width > 0) {
                    const opacity =
                        canvas.parentNode.style.opacity || canvas.style.opacity;
                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

                    const backgroundColor = canvas.parentNode.style.backgroundColor;
                    if (backgroundColor) {
                        mapContext.fillStyle = backgroundColor;
                        mapContext.fillRect(0, 0, canvas.width, canvas.height);
                    }

                    let matrix;
                    const transform = canvas.style.transform;
                    if (transform) {
                        // Get the transform parameters from the style's transform matrix
                        matrix = transform
                            .match(/^matrix\(([^\(]*)\)$/)[1]
                            .split(',')
                            .map(Number);
                    } else {
                        matrix = [
                            parseFloat(canvas.style.width) / canvas.width,
                            0,
                            0,
                            parseFloat(canvas.style.height) / canvas.height,
                            0,
                            0,
                        ];
                    }
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix
                    );
                    mapContext.drawImage(canvas, 0, 0);
                }
            }
        );
        mapContext.globalAlpha = 1;
        if (navigator.msSaveBlob) {
            // link download attribute does not work on MS browsers
            navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
        } else {
            const link = document.getElementById('image-download');
            link.href = mapCanvas.toDataURL();
            link.click();
        }
        map.renderSync();
    }

    function MovemouseonProfilepIc() {
        document.getElementById('diVProfilePic').style.display = 'inline';
    }

    function mouseOutOnrofilepIc() {
        document.getElementById('diVProfilePic').style.display = 'none';
    }

    function LogoutSession() {
        localStorage.removeItem('token-info');
        clearLocalStorage()
        window.location.href = '/';
    }

    function ZoomInImage() {
        var myImg = document.getElementById("imgPopupDataImage");
        var currWidth = myImg.clientWidth;
        var currHeight = myImg.clientHeight;
        myImg.style.width = (currWidth + 50) + "px";
        myImg.style.height = (currHeight + 50) + "px";
    }

    function ZoomOutImage() {
        var myImg = document.getElementById("imgPopupDataImage");
        var currWidth = myImg.clientWidth;
        var currHeight = myImg.clientHeight;
        myImg.style.width = (currWidth - 50) + "px";
        myImg.style.height = (currHeight - 50) + "px";

    }

    const handleselectedFile = event => {
        setFile(event.target.files[0]);

        event.preventDefault();
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
        };
        reader.readAsText(event.target.files[0]);
    }

    const handleUpload = (event) => {
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            if (document.getElementById('cmbDataFormatsImport').selectedIndex == 0) {
                const vectorSourceOutData = new VectorSource({
                    features: new GeoJSON().readFeatures(text),
                });
                const vectorLayerOutLayer = new VectorLayer({
                    source: vectorSourceOutData,
                    style: new Style({
                        stroke: new Stroke({
                            color: 'rgba(255,0,0, 1)',
                            width: 2
                        })
                    })
                });
                mapforArrowFunction.addLayer(vectorLayerOutLayer);
                var extent = vectorSourceOutData.getExtent();
                mapforArrowFunction.getView().fit(extent);
                mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() - 1);
            }
            if (document.getElementById('cmbDataFormatsImport').selectedIndex == 1) {
                const vectorSourceOutData = new VectorSource({
                    features: new KML().readFeatures(text),
                });
                const vectorLayerOutLayer = new VectorLayer({
                    source: vectorSourceOutData,
                });
                mapforArrowFunction.addLayer(vectorLayerOutLayer);
                var extent = vectorSourceOutData.getExtent();
                mapforArrowFunction.getView().fit(extent);
                mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() - 1);
            }
            if (document.getElementById('cmbDataFormatsImport').selectedIndex == 2) {
                if (selectedFile.name.slice(-3) != 'zip')
                    return
                // Specify the URL of the Ogre API endpoint
                const url = 'http://ogre.adc4gis.com/convert'

                // Create a new FormData object
                const formData = new FormData();
                formData.append('upload', selectedFile);

                // Send the FormData object to the API endpoint using fetch()
                fetch(url, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        // Check if the response status is OK
                        if (response.ok) {
                            // Parse the response JSON data
                            return response.json();
                        } else {
                            // Display the error message
                            throw new Error('Network response was not ok');
                        }
                    })
                    .then(data => {
                        const vectorSourceOutData = new VectorSource({
                            features: new GeoJSON().readFeatures(JSON.stringify(data))
                        });
                        const vectorLayerOutLayer = new VectorLayer({
                            source: vectorSourceOutData,
                        });
                        mapforArrowFunction.addLayer(vectorLayerOutLayer);
                        var extent = vectorSourceOutData.getExtent();
                        mapforArrowFunction.getView().fit(extent);
                        mapforArrowFunction.getView().setZoom(mapforArrowFunction.getView().getZoom() - 1);
                    })
                    .catch(error => {
                        // Display the error message
                        console.error('There was a problem with the fetch operation:', error);
                    });
            }

            document.getElementById('importDIV').style.display = 'none';
        };
        if (document.getElementById('cmbDataFormatsImport').selectedIndex == 2) {
            reader.readAsArrayBuffer(selectedFile)
        }
        else {
            reader.readAsText(selectedFile);
        }
    }

    return (
        <div>
            <div id="wrapper">


                <ul className="navbar-nav sidebar sidebar-light " id="accordionSidebar" >
                    <a className="sidebar-brand d-flex align-items-center justify-content-center" >
                        <div className="sidebar-brand-icon" style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                            <img src={require('./img/logo/logo2.png')} alt="" />
                        </div>
                    </a>
                    <hr className="sidebar-divider my-0" />
                    <li className="nav-item" >
                        <a className="nav-link collapsed" data-target="#collapseForm" aria-expanded="true"
                            aria-controls="collapseForm" style={{ alignContent: 'center' }} onClick={showTreeviewDIV}>
                            &nbsp;&nbsp;&nbsp;<img src={require('./img/treeview_white1.png')} alt="" style={{ width: "20px", height: "32px", color: 'white', filter: 'brightness(0) invert(1)' }} /> <br></br>
                            <span style={{ color: 'white', fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>Treeview</span>
                        </a>

                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-target="#collapseTable" aria-expanded="true"
                            aria-controls="collapseTable" onClick={showMeasureDIV}>
                            &nbsp;&nbsp;&nbsp;<img src={require('./img/measure.png')} alt="" style={{ width: "27px", height: "27px", filter: 'brightness(0) invert(1)' }} /><br></br>
                            <span style={{ color: 'white', fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>Measure</span>
                        </a>

                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-target="#collapseTable" aria-expanded="true"
                            aria-controls="collapseTable" onClick={showfiltersDIV} >
                            {/* <i className="fas fa-fw fa-table"></i> */}
                            &nbsp;&nbsp;&nbsp;<img src={require('./img/query.png')} alt="" style={{ width: "27px", height: "27px", filter: 'brightness(0) invert(1)' }} /><br></br>
                            &nbsp;&nbsp;<span style={{ color: 'white', fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>Filters</span>
                        </a>

                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-target="#collapseTable" aria-expanded="true"
                            aria-controls="collapseTable" onClick={showPrintDIV}>
                            &nbsp;&nbsp;<img src={require('./img/print.png')} alt="" style={{ width: "32px", height: "32px", filter: 'brightness(0) invert(1)' }} /><br></br>
                            &nbsp;&nbsp;&nbsp;<span style={{ color: 'white', fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>Print</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-target="#collapseTable" aria-expanded="true"
                            aria-controls="collapseTable" onClick={showExportDIV}>
                            &nbsp;&nbsp;<img src={require('./img/export_main.png')} alt="" style={{ width: "32px", height: "32px", filter: 'brightness(0) invert(1)' }} /><br></br>
                            &nbsp;<span style={{ color: 'white', fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>Export</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" data-target="#collapseTable" aria-expanded="true"
                            aria-controls="collapseTable" onClick={showImportDIV}>
                            &nbsp;&nbsp;<img src={require('./img/import.png')} alt="" style={{ width: "32px", height: "32px", filter: 'brightness(0) invert(1)' }} /><br></br>
                            &nbsp;<span style={{ color: 'white', fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>Import</span>
                        </a>
                    </li>
                </ul>
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <nav className="navbar navbar-expand navbar-light bg-navbar topbar mb-0.5 static-top">
                            <button id="sidebarToggleTop1" className="btn btn-link rounded-circle mr-3 sidebar-toggle">
                                <div className="sidebar-brand-text mx-3" style={{ fontWeight: "bold", fontSize: "20px", fontFamily: 'Poppins, sans-serif', color: '#343a40' }}>STYRA Geospatial Application
                                </div>
                            </button>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item dropdown no-arrow mx-1">
                                    <a className="nav-link dropdown-toggle" id="alertsDropdown" role="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" >
                                        <select style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select d-none' id="cmbLocationNames" onChange={onselectedLocation} >
                                        </select>
                                    </a>
                                </li>
                                <div className="topbar-divider d-none d-sm-block"></div>
                                <li className="nav-item dropdown no-arrow">
                                    <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" style={{ cursor: 'pointer' }} onMouseOver={MovemouseonProfilepIc} onMouseOut={mouseOutOnrofilepIc}>
                                        <img className="img-profile rounded-circle" src={require('./img/boy.png')} alt="" style={{ maxWidth: "60px" }} />
                                        <span id="lblloggedUser" style={{ fontSize: '12px', color: 'black', fontFamily: 'Poppins, sans-serif' }}>Demo User</span>
                                    </a>
                                    <div id='diVProfilePic' className="dropdown-menu dropdown-menu-right shadow animated--grow-in" style={{ top: '80%' }} aria-labelledby="userDropdown" onMouseOver={MovemouseonProfilepIc} onMouseOut={mouseOutOnrofilepIc}>
                                        <a className="dropdown-item" data-toggle="modal" data-target="#logoutModal" style={{ cursor: 'pointer' }} onClick={LogoutSession}>
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                            Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <div className="map-container" id="map" style={{ width: "100%", height: "89.4%", scrollbarWidth: 'thin', overflowX: 'auto', overflowY: 'auto' }}></div>
                        <img id='progressDIV' src={require('./img/progress.gif')} style={{ position: 'absolute', bottom: "20%", right: "35%", display: 'none', zIndex: 3000 }}></img>
                        <div align="center" style={{ width: "260px", height: "25px", position: 'absolute', bottom: "1%", right: "1%", backgroundColor: "white", fontSize: "13px" }} >
                            <p _ngcontent-fka-c78="" className="value" id="lblCoordinates"> 0.00 </p>
                        </div>

                        {/* Layer treeview Panel */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="TreeviewDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "15%", right: "0.5%", width: "19%", height: "70%", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Map - Layer Treeview
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closeTreeviewDIV} />
                            </h6>
                            <div className="media friendlist-main">
                                <h6 className='MeasureLabel' style={{ fontSize: "12px", fontWeight: 'bold' }}>Base Map Layers</h6>
                            </div>
                            <div style={{ paddingLeft: "20px" }}>
                                <input type="radio" id="osmmap" value="OSM Map" onClick={showOSM} checked />
                                <label for="html" className="context-menu MeasureLabel" >OSM Map</label><br></br>
                                <input type="radio" id="bingmap" value="Bing Map" onClick={showBIngMap} />
                                <label for="css" className="context-menu MeasureLabel" style={{ fontSize: "12px" }}>Bing Map</label><br></br>
                                <input type="radio" id="bingimagery" value="Bing Imagery" onClick={showBingImagery} />
                                <label for="javascript" className="context-menu MeasureLabel" style={{ fontSize: "12px" }}>Bing Imagery</label><br></br>
                                <input type="radio" id="bingimagerywithlabels" value="Bing Imagery with Labels" onClick={showBingImagerywithLabels} />
                                <label for="javascript" className="context-menu MeasureLabel" style={{ fontSize: "12px" }}>Bing Imagery with Labels</label><br></br>
                                <input type="radio" id="googleterrain" value="Google Terrain Map" onClick={showgoogleTerrainMap} />
                                <label for="javascript" className="context-menu MeasureLabel" style={{ fontSize: "12px" }}>Google Terrain Map</label><br></br>
                                <input type="radio" id="usgstopo" value="USGS Topo Map" onClick={showusgsTopoMap} />
                                <label for="javascript" className="context-menu MeasureLabel" style={{ fontSize: "12px" }}>USGS Topo Map</label><br></br>
                                <input type="radio" id="none" value="None" onClick={shownoimage} />
                                <label for="javascript" className="context-menu MeasureLabel" style={{ fontSize: "12px" }}>None</label><br></br>
                            </div>
                            <h6 className='MeasureLabel' style={{ fontSize: "12px", fontWeight: 'bold' }}>Vector & Raster Layers</h6>
                            <table id="TreeviewTable" style={{ overflowX: "auto", overflowY: "auto", fontSize: "12px", paddingLeft: '20px' }} class="context-menu">
                            </table>
                        </div>


                        {/* Measurement Panel */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="MeasurementDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "15%", right: "0.5%", width: "19%", height: "70%", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '1rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Measurement Analysis
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closeMeasureDIV} />
                            </h6>
                            <div _ngcontent-deb-c80="" className="section">
                                <div _ngcontent-deb-c80="" className="options">

                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="distance">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }}>
                                            <div id="Div5" align="center" onClick={drawLine} onMouseOver={changeBackground} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }} >
                                                <img _ngcontent-deb-c54="" src={require('./img/distance.jpg')} alt="distance" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Distance</p>
                                        </div>
                                    </app-icon>
                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="area">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }} className='MeasureLabel'>

                                            <div id="Div6" align="center" onClick={drawArea} onMouseOver={changeBackground} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                                                <img _ngcontent-deb-c54="" src={require('./img/area.png')} alt="area" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Area</p>
                                        </div>
                                    </app-icon>
                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="clear">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }}>
                                            <div id="Div11" align="center" onClick={handleClickEventclearSelection} onMouseOver={changeBackground} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                                                <img _ngcontent-deb-c54="" src={require('./img/clear2.png')} alt="query" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Clear</p>
                                        </div>
                                    </app-icon>
                                </div>
                                <br></br>
                                &nbsp; &nbsp; &nbsp;<label id="lblMeasureReults" className='MeasureLabel'>Result:</label><br></br>
                            </div>
                            <div _ngcontent-deb-c80="" className="section">
                                <p className='MeasureLabel' style={{ fontWeight: 'bold', paddingLeft: "1em" }}>Unit Settings</p><br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;<label align="left" className='MeasureLabel'>Measure Area in:</label>
                                <select name="areaUnits" id="aUniId" style={{ fontSize: "12px" }} className='m-2 bg-white border rounded p-2 form-select' onChange={aUnitChange}>
                                    <option value="acres">Acres</option>
                                    <option value="hectares">Hectares</option>
                                    <option value="sqMeters">Square Meters</option>
                                    <option value="sqKms">Square KMs</option>
                                    <option value="sqFeet">Square Feets</option>
                                </select>
                                <br /><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;<label align="left" className='MeasureLabel'>Measure Length in:</label>
                                <select name="lengthUnits" id="lUniId" style={{ fontSize: "12px" }} className='m-2 bg-white border rounded p-2 form-select' onChange={lUnitChange}>
                                    <option value="meters">Meters</option>
                                    <option value="kms">KMs</option>
                                    <option value="feets">Feets</option>
                                    <option value="miles">Nautical Miles</option>
                                </select>
                                <br /><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;<label align="left" className='MeasureLabel'>Coordinates:</label>
                                <select name="coorDis" id="cDisId" style={{ fontSize: "12px" }} className='m-2 bg-white border rounded p-2 form-select' onChange={cDisChange}>
                                    <option value="decimal">Degree Decimal</option>
                                    <option value="dms">Deg.Min.Sec.</option>
                                </select>
                                <br /><br />
                            </div>
                        </div>
                        <a id="image-download" download="map.png"></a>

                        {/* for popup attributes */}

                        <div id="popup" className="ol-popup1" >
                            <a href="#" id="popup-closer" className="ol-popup-closer"></a>
                            <div id="popup-content"></div>
                        </div>

                        {/* progress Panel */}

                        {/* Print Panel    */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="printDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "15%", right: "0.5%", width: "19%", height: "42%", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Print Map
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closeprintDIV} />
                            </h6>
                            <div _ngcontent-deb-c80="" className="section">
                                &nbsp;&nbsp;&nbsp;<label for="format" className='MeasureLabel'>Page size </label>&nbsp;&nbsp;&nbsp;
                                <select id="format" style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select'>
                                    <option value="a0">A0</option>
                                    <option value="a1">A1</option>
                                    <option value="a2">A2</option>
                                    <option value="a3">A3</option>
                                    <option value="a4" selected>A4</option>
                                    <option value="a5">A5</option>
                                </select> <br></br>
                                &nbsp;&nbsp;&nbsp;<label for="resolution" className='MeasureLabel'>Resolution </label>&nbsp;
                                <select id="resolution" style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select'>
                                    <option value="72">72 dpi (fast)</option>
                                    <option value="150">150 dpi</option>
                                    <option value="300">300 dpi (slow)</option>
                                </select>
                                <br></br>
                                &nbsp;&nbsp;&nbsp;<label className='MeasureLabel'>Map Title:</label>
                                &nbsp;&nbsp;<input type="text" id='txtMaptitle' style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#343a40' }}></input><br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button id="btnAddTitle" className="btn btn-primary text-white" style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#343a40' }} onClick={addTitletoMap}>Add Map Title</button>&nbsp;
                                &nbsp;<button className="btn btn-primary text-white" id="btnAddTitle" style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#343a40' }} onClick={clearMapTitle}>Clear</button>
                                <br></br><br></br>
                                <p align="center">  <button className="btn btn-primary text-white" id="btnExportPDFMAP" style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#343a40' }} onClick={printDocument}>Print Map</button></p>
                                <br></br><br></br>
                            </div>
                        </div>

                        {/* Filters Panel    */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="filtersDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "15%", right: "1%", width: "600px", height: "400px", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none', overflowX: 'auto', overflowY: 'auto' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Filters
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closefiltersDIV} />
                            </h6>
                            <br></br>
                            &nbsp; <label style={{ fontSize: "13px", fontWeight: 'bold' }}>Select Category:</label> &nbsp;
                            <select id="cmbFiltersCategory" style={{ fontSize: "12px" }} className="bg-white border rounded p-2 form-select" onChange={onselectedCategory} >
                            </select>&nbsp;&nbsp;
                            <label style={{ fontSize: '12px', cursor: 'pointer' }} onClick={onResetFilters}><u> Reset </u></label>
                            <br></br>
                            &nbsp;<label id="lblAssettypes" style={{ fontSize: "14px", fontWeight: 'bold', display: 'none' }}>Asset Types:</label>  <br></br>
                            &nbsp;&nbsp;<table id="filterAssettypesTable" style={{ fontSize: "12px", display: 'none', paddingLeft: '10px' }} >
                            </table><br></br>
                            &nbsp;<label id="lblLocationHeading" style={{ fontSize: "14px", fontWeight: 'bold', display: 'none' }}>Location:</label>  <br></br>
                            &nbsp;  <label id="lblofficetypes" style={{ fontSize: "12px", display: 'none' }}>Office Types:</label>  &nbsp;
                            <select style={{ fontSize: "12px", display: 'none' }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' id="cmbOfficetypesfilters" onChange={onSelectedOfficeType}></select>
                            &nbsp;&nbsp;&nbsp;<label id="lblLocation" style={{ fontSize: "12px", display: 'none' }}>Location:</label>   &nbsp;
                            <input type="text" id="cmlocationsfilters" style={{ fontSize: "12px", display: 'none' }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' autoComplete="off" onChange={e => onChangeLocation(e.target.value)} value={text} ></input>
                            {suggestions && suggestions.map((suggesion, i) =>
                                <div key={i} style={{ fontSize: "13px", backgroundColor: '#FAFAFA', color: '#6777EF', paddingLeft: '330px' }} className="suggesion col-md-12 justify-content-md-center" onClick={() => { onSuggestHandler(suggesion.location) }}>{suggesion.location}</div>
                            )}<br></br>
                            &nbsp;<label id="lblparenthierarchy" style={{ fontSize: "13px", fontWeight: 'bold', display: 'none' }}>Parent Hierarchy:</label>  <br></br>
                            &nbsp;&nbsp;  <label id="lblParentAssettypes" style={{ fontSize: "12px", display: 'none' }}>Parent Asset Types:</label>  &nbsp;

                            <select id="cmbParentAssetTypes" style={{ fontSize: "12px", display: 'none', width: '130px' }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' onChange={onSelectedParentAssetType}></select>
                            &nbsp;&nbsp;&nbsp;&nbsp; <label id="lblParentAssets" style={{ fontSize: "12px", display: 'none' }}>Parent Assets:</label>  &nbsp;
                            <select id="cmbParentAssets" onChange={onChangeParentAssets} style={{ fontSize: "12px", display: 'none', width: '130px' }} className='ms-2 mb-2 bg-white border rounded p-2 form-select'></select><br></br><br></br>
                            <table id="FiltersTable" style={{ fontSize: "12px", display: 'none', paddingLeft: '10px' }} >
                            </table>
                            <br></br>
                            <br></br>
                            <div id='DIVSpatialQuery' style={{ display: 'none' }}>
                                &nbsp;&nbsp;<input type="checkbox" id="chkSPatilQueryFilters" onChange={onchangechckSpatialQueryFilters}></input>
                                &nbsp;&nbsp;<label className='MeasureLabel'>Spatial Query</label>
                                &nbsp;&nbsp;<input type='button' id='btnDrawpolygonFiltersSpatialQuery' value='Draw Boundary' onClick={onDrawpolygonSPatialQuery} style={{ fontSize: '12px', color: '#343a40', fontFamily: 'Georgia, Times New Roman, Times, serif', display: 'none' }} ></input>
                                &nbsp;&nbsp;<input type='button' id='btnClearSparialQueryPolygons' value='Clear' onClick={onclearSpatialQueryPolygons} style={{ fontSize: '12px', color: '#343a40', fontFamily: 'Georgia, Times New Roman, Times, serif', display: 'none' }} ></input>
                                &nbsp;&nbsp;<input type='checkbox' id='chkInsideBoundary' style={{ fontSize: '12px', color: '#343a40', fontFamily: 'Georgia, Times New Roman, Times, serif', display: 'none' }}></input>
                                &nbsp;&nbsp;<lable id='lblInsideBoundary' style={{ fontSize: '12px', color: '#343a40', fontFamily: 'Georgia, Times New Roman, Times, serif', display: 'none' }}>Inside Boundary</lable>
                            </div>
                            <br></br>
                            <br></br>
                            <div id='DIVAdvancedFilters' style={{ display: 'none' }}>
                                &nbsp;&nbsp;<label style={{ fontSize: "13px", fontWeight: 'bold' }}>Advanced Filters:</label>  <br></br>
                                &nbsp;&nbsp;<input type="checkbox" id="chkAdvancedFilter1" onChange={onchangeAdvancedFilters1} ></input>
                                &nbsp;&nbsp;<input type="text" placeholder='Asset Code' id='txtAssetcodeAdvancedFilters1' style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' disabled></input>
                                &nbsp;&nbsp;<input type="text" placeholder='Asset Name' id='txtAssetnameAdvancedFilters1' style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' disabled></input><br></br><br></br>

                                &nbsp;&nbsp;<input type="checkbox" id='chkAdvancedFilters3' style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' onChange={onchangeAdvancedFilters3}></input>
                                &nbsp;&nbsp; <input type="text" id="cmbPOIAdvancedfilters3" style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' autoComplete="off" onChange={e => onChangeProperrtyData(e.target.value)} placeholder="Property Data" value={PropertyText} disabled></input>
                                &nbsp;&nbsp;{suggestions && suggestions.map((suggesion, i) =>
                                    <div key={i} style={{ fontSize: "13px", paddingLeft: '40px', backgroundColor: '#FAFAFA', color: '#343a40' }} onClick={() => { onSuggestHandlerPropertyData(suggesion.propertyname) }}>{suggesion.propertyname}</div>
                                )}
                                <table border="0" style={{ marginLeft: '35px' }}>
                                    <tr>
                                        <td>
                                            <input type='date' id='txtfromDateAdvancedFilters3' className='ms-2 mb-2 bg-white border rounded p-2 form-select' style={{ width: '120px', heigt: '35px', fontSize: "12px", display: 'none' }}></input>
                                        </td>
                                        <td>
                                            <input type='date' id='txttoDateAdvancedFilters3' className='ms-2 mb-2 bg-white border rounded p-2 form-select' style={{ width: '120px', heigt: '35px', fontSize: "12px", display: 'none' }}></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="text" id='txtAdvancedFilters3' className='ms-2 mb-2 bg-white border rounded p-2 form-select' style={{ width: '240px', height: '35px', fontSize: "12px", display: 'none' }} ></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="text" id='txtFromintegerAdvancedFilters3' className='ms-2 mb-2 bg-white border rounded p-2 form-select' style={{ width: '120px', height: '35px', fontSize: "12px", display: 'none' }} ></input>
                                        </td>
                                        <td>
                                            <input type="text" id='txtTointegerAdvancedFilters3' className='ms-2 mb-2 bg-white border rounded p-2 form-select' style={{ width: '120px', height: '35px', fontSize: "12px", display: 'none' }} ></input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select id='cmbAdvancedfilters3' className='ms-2 mb-2 bg-white border rounded p-2 form-select' style={{ fontSize: "12px", width: '240px', height: '35px', fontSize: "12px", display: 'none' }} onChange={onchangeAdvancedFilters3property}></select>

                                        </td>
                                    </tr>
                                </table>
                                <br></br>
                                &nbsp;&nbsp;<input type="checkbox" id="chkAdvancedFilter2" style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' onChange={onchangeAdvancedFilters2}></input>
                                &nbsp;&nbsp; <input type="text" id="txtPOIAdvancedfilters2" placeholder='POI' style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' autoComplete="off" onChange={e => onChangePOIData(e.target.value)} value={POItext} disabled></input>
                                &nbsp;&nbsp;{suggestions && suggestions.map((suggesion, i) =>

                                    <div key={i} style={{ fontSize: "13px", paddingLeft: '40px', backgroundColor: '#FAFAFA', color: '#343a40' }} onClick={() => { onSuggestHandlerPOI(suggesion.strg) }}>{suggesion.strg}</div>
                                )}
                                <br></br><br></br><br></br><br></br><br></br><br></br>

                            </div>
                        </div>

                        {/* Right side navigation controls    */}
                        <div style={{ position: 'absolute', top: "25%", right: "0.5%", height: "275px", width: "50px", padding: "2px", backgroundColor: 'white', borderRadius: '10px' }}>

                            <table border="0" style={{ borderCollapse: 'separate', borderSpacing: '0 5px', marginLeft: "10px", alignContent: 'center' }} >
                                <tr>
                                    <td>
                                        <img title='Zoom In' src={require('./img/plus_new.png')} width='20px' height='20px' onClick={handleClickEventZoomIn} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Zoom Out' src={require('./img/minus_new.png')} width='20px' height='20px' onClick={handleClickEventZoomOut} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Rubberband Zoom' src={require('./img/zoomin_new.png')} width='25px' height='25px' onClick={handleClickEventRubberbandZoom} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Pan' src={require('./img/hand.png')} width='22px' height='22px' onClick={handlePanClick} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Feature Info' src={require('./img/info_new.png')} style={{ backgroundColor: 'white' }} width='20px' height='20px' id='imgInfo' onClick={handleClickEventInfo} ></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Draw Boundary' src={require('./img/polygon_new.png')} style={{ backgroundColor: 'white', color: "#336699" }} width='20px' height='20px' id='imgDrawing' onClick={showDrawingDIV} onMouseOver={changeBackground1} onMouseOut={KeepBackground1} ></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Show connectivity for selected asset' src={require('./img/connectivity_new.png')} style={{ backgroundColor: 'white' }} width='20px' height='20px' id='imgConnectivity' onClick={showConnectivityforSelectedAsset} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img title='Show Table Data' src={require('./img/table_new.png')} width='21px' height='20px' onClick={handleClickEventTableData} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <img title='Clear' src={require('./img/clear2.png')} width='20px' height='20px' style={{ marginBottom: '5px' }} onClick={handleClickEventclearSelection} onMouseOver={changeBackground1} onMouseOut={KeepBackground1}></img>
                                    </td>
                                </tr>

                            </table>

                        </div>

                        {/* Drawing polygon Panel */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="DrawingDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "15%", right: "0.5%", width: "19%", height: "60%", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Draw Boundary
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closeDrawingDIV} />
                            </h6>
                            <div _ngcontent-deb-c80="" className="section">
                                &nbsp;&nbsp;<label id="lblLocationHeading" className='MeasureLabel' style={{ fontWeight: 'bold' }}>Location:</label>  <br></br>
                                &nbsp;  <label id="lblofficetypes" className='MeasureLabel'>Office Types:</label>  &nbsp; &nbsp;
                                <select id="cmbOfficetypesfiltersDrawPolygons" onChange={onSelectedOfficeTypeDrawPolygon} style={{ fontSize: "12px", width: "55%", height: "25px", display: 'inline' }}></select><br></br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <label id="lblLocation" className='MeasureLabel'>Location:</label>   &nbsp; &nbsp;
                                <input type="text" id="cmlocationsfiltersDrawPolygons" style={{ fontSize: "14px", width: "55%", height: "25px", display: 'inline' }} autoComplete="off" onChange={e => onChangeLocationDrawPolygon(e.target.value)} value={text} ></input>
                                {suggestions && suggestions.map((suggesion, i) =>
                                    <div key={i} style={{ fontSize: "13px", backgroundColor: '#FAFAFA', color: 'black', fontWeight: 'bold', paddingLeft: '100px' }} className="suggesion col-md-12 justify-content-md-center" onClick={() => { onSuggestHandlerDrawPolygon(suggesion.location) }}>{suggesion.location}</div>
                                )}<br></br>
                                &nbsp;&nbsp;&nbsp;<input type='checkbox' id='chkFreehanddrawing'></input> <label className='MeasureLabel'>Freehand Drawing</label><br></br>
                                <div _ngcontent-deb-c80="" className="options">
                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="distance">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }}>
                                            <div id="Div5" align="center" onClick={selectInteratiom} onMouseOver={changeBackground} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }} >
                                                <img _ngcontent-deb-c54="" src={require('./img/select.png')} alt="distance" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Select</p>
                                        </div>
                                    </app-icon>
                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="distance">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }}>
                                            <div id="Div5" align="center" onClick={addInteractioninDrawingPolygon} onMouseOver={changeBackground} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }} >
                                                <img _ngcontent-deb-c54="" src={require('./img/polygon.png')} alt="distance" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Polygon</p>
                                        </div>
                                    </app-icon>
                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="clear">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }}>
                                            <div id="Div11" align="center" onClick={removeFeaure} onMouseOver={changeBackground} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                                                <img _ngcontent-deb-c54="" src={require('./img/clear2.png')} alt="query" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Delete</p>
                                        </div>
                                    </app-icon>
                                    <app-icon _ngcontent-deb-c80="" filter="var(--ico-inversion)" _nghost-deb-c54="" ng-reflect-filter="var(--ico-inversion)" ng-reflect-icon="area">
                                        <div _ngcontent-deb-c54="" style={{ margin: "0.3rem" }}>
                                            <div id="Div6" align="center" onMouseOver={changeBackground} onClick={exportDrawingFeatures} onMouseOut={KeepBackground} style={{ height: "42px", width: "42px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "10px" }}>
                                                <img _ngcontent-deb-c54="" src={require('./img/export1.png')} alt="area" style={{ width: "32px", height: "32px" }} />
                                            </div>
                                            <p className='MeasureLabel'>Export</p>
                                        </div>
                                    </app-icon>
                                </div>
                            </div>

                            <p align="center">
                                <input type="button" id='btnSaveBoundary' value="Save Boundary" onClick={SaveBoundary} className='MeasureLabel'></input>
                            </p>

                        </div>

                        {/* export Panel    */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="exportDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "35%", right: "40%", width: "19%", height: "22%", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Export Layers
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closeExportDIV} />
                            </h6>
                            <div _ngcontent-deb-c80="" className="section">
                                &nbsp;&nbsp;&nbsp;<label for="resolution" className='MeasureLabel'>Format </label>&nbsp;
                                <select style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select' id="cmbDataFormats">
                                    <option value="GeoJSON">GeoJSON</option>
                                    <option value="KML">KML</option>
                                    <option value="SHP">SHP</option>
                                </select>
                                <br></br>
                                <p align="center"><button id="btnExportData" className="btn btn-primary text-white" style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#343a4' }} onClick={exportData}>Export</button></p>
                            </div>
                        </div>

                        {/* popup Panel    */}

                        <div className="dropdown-list  dropdown-menu-right shadow ol-popup" id="popupDIV"
                            aria-labelledby="messagesDropdown" style={{ width: "750px", height: "450px", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none', top: '15%', left: '7%' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Asset Info
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closepopupDIV} />
                            </h6>
                            <div _ngcontent-deb-c80="" className="section" >
                                <div style={{ width: "270px", height: "320px", float: 'left', overflowX: 'auto', overflowY: 'auto' }}>
                                    <label align='left' id='imgpopupImageName' style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#336699', fontWeight: 'bold', position: 'absolute', top: '10%', left: '1%' }}></label>
                                    <br></br>
                                    <img id='imgPopupDataImage' width="235px" height="200px"></img>

                                    <input type="button" id='btnPopupImagePrevious' className="btn btn-primary rounded" value="<" style={{ position: 'absolute', top: "50%", left: "1%", backgroundColor: '#336699', color: 'white' }}></input>
                                    <input type="button" id="btnPopupImageNext" className="btn btn-primary rounded" value=">" style={{ position: 'absolute', top: "50%", left: "240px", backgroundColor: '#336699', color: 'white' }}></input>

                                    <input type="button" id="btnZoomInImage" value="+" className="btn btn-primary rounded" style={{ position: 'absolute', top: "85%", left: "105px", backgroundColor: '#336699', color: 'white' }} onClick={ZoomInImage}></input>
                                    <input type="button" id="btnZoomOutImage" value="-" className="btn btn-primary rounded" style={{ position: 'absolute', top: "85%", left: "150px", backgroundColor: '#336699', color: 'white' }} onClick={ZoomOutImage}></input>
                                </div>
                                <div style={{ width: "455px", height: "370px", paddingLeft: "10px", float: 'right', overflowX: 'auto', overflowY: 'auto' }} id='popupcontent'>
                                </div>

                            </div>

                        </div>


                        {/* Data Table    */}

                        <div id='bottomTableDIV' style={{ position: 'absolute', bottom: "0%", left: "6.3%", height: "50%", width: "93.5%", zIndex: 1000, backgroundColor: 'white', fontSize: '13px', scrollbarWidth: 'thin', display: 'none' }}>
                            <div style={{ height: '15%', width: '100%', position: 'absolute', top: 0 }}>
                                <div style={{ fontWeight: 'bold', fontSize: '12px', position: 'absolute', right: '1%', top: "-19%" }}>
                                    <input type='button' className='btn-primary rounded' value='Download' style={{ fontSize: '13px', backgroundColor: '#EBEBEB' }} onClick={DownloadbottomTableDIV}></input>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type='button' className='btn-primary rounded' value='Close' style={{ fontSize: '13px', backgroundColor: '#EBEBEB' }} onClick={closebottomTableDIV}></input>
                                </div>
                                <label id='selectedCategory' style={{ margin: "1em 0 0 1em", fontWeight: 'bold', fontSize: '12px', color: 'blue' }}></label>
                                <label id='indicatorName' style={{ fontWeight: 'bold', fontSize: '12px', color: 'blue' }}></label>
                                <label id='lblTotalRecordinBottomTable' style={{ margin: "1em 0 0 1em", fontWeight: 'bold', fontSize: '12px', color: 'blue' }}></label>

                            </div>

                            <div style={{ width: '100%', height: '93%', border: '0.5px solid gray', position: 'absolute', overflowX: 'auto', overflowY: 'auto', bottom: 0 }}>
                                <table id="tblBottomTable" style={{ marginLeft: "4em", marginTop: "1em", width: '100%' }} className="table table-striped MeasureLabel">
                                    <thead>
                                        <p></p>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>

                        </div>


                        {/* Import Panel    */}

                        <div className="dropdown-list  dropdown-menu-right shadow " id="importDIV"
                            aria-labelledby="messagesDropdown" style={{ position: 'absolute', top: "35%", right: "40%", width: "19%", height: "35%", backgroundColor: "rgba(255,255,255,1)", borderRadius: "10px", zIndex: 6, display: 'none' }}>
                            <h6 className="dropdown-header" style={{ backgroundColor: "#F5F5F5", border: "0px solid #3f51b5", paddingTop: ".75rem", paddingBottom: ".75rem", color: "#343a40", paddingLeft: '0.25rem', fontWeight: 'bold', borderRadius: "5px" }}>
                                Import Layers
                                <img align="right" src={require('./img/clear2.png')} alt="" style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={closeImportDIV} />
                            </h6>
                            <div _ngcontent-deb-c80="" className="section">


                                &nbsp;&nbsp;&nbsp;<label for="resolution" className='MeasureLabel'>Format </label>&nbsp;
                                <select id="cmbDataFormatsImport" style={{ fontSize: "12px" }} className='ms-2 mb-2 bg-white border rounded p-2 form-select'>
                                    <option value="GeoJSON">GeoJSON</option>
                                    <option value="KML">KML</option>
                                    <option value="SHP">SHP</option>
                                </select>
                                <br></br><br></br>

                                <input type="file" className='MeasureLabel' onChange={handleselectedFile} /><br></br><br></br>

                                <p align="center"><button id="btnImporttData" className="btn btn-primary text-white" style={{ fontSize: "12px", fontFamily: 'Poppins, sans-serif', color: '#343a4' }} onClick={handleUpload}>Import</button></p>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div >
    );
}

export default App;
