import axios from "axios";
import "./AxiosInterceptor";


const BASE_URL = process.env.REACT_APP_STYRA_API  //TODO : use from env file

export const login = (email, password) => {
    
    const body = {
        username: email,
        password: password,
        clientIdentifier: 'GIS_WEB'
    }
    
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${BASE_URL}/login`,
                body,
            )
            .then((res) => {
                if (res.status === 0) {
                    return resolve(res.data)
                } else {
                    reject(res.description);
                }
            })
            .catch(e => {
                console.log(e);
                reject(e.message);
            }) //TODO : send formatted message
    })
}

export const httpGet = ({ path, queryParams = null }) => {
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}/${path}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }) //TODO : send formatted message
    })
}

export const httpPost = ({ path, queryParams = null, body = null }) => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${BASE_URL}/${path}`,
                body
            )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e);
                reject(e.message);
            }) //TODO : send formatted message
    })
}

export const fetchImage = async (imgUrl) => {
    return new Promise((resolve, reject) => {
        axios.get(imgUrl, {
            responseType: 'blob',
        }).then((res) => {
            resolve(URL.createObjectURL(res))

        }).catch(e => {
            console.log(e);
            reject(e.message);
        })
    })
};
