import axios from "axios";
import { clearLocalStorage, getAuthRefreshToken, getAuthToken, removeAuthToken, setAuthToken, setRefreshToken } from "../util/local-storage-util";
import { KEY_X_AUTH_TOKEN, KEY_X_REFRESH_TOKEN } from "./ApiConstants";

const BASE_URL = process.env.REACT_APP_STYRA_API //TODO : use from env or config file


// open urls to exclude headers and 401 errors
const OPEN_URL_LIST = [
    '/login',
    '/refresh-token'
]

const FILE_URL_LIST = []

const AUTHENTICATE = '/login'
const REFRESH_TOKEN = '/refresh-token'

let isRefreshing = false; // Track whether a token refresh is in progress
let refreshSubscribers = []; // Array to hold the pending API requests

const handleServerError = (error) => {

    if (error && error.response && error.response.data && error.response.data.message) {
        return Error(error.response.data.message)
    }

    if(error.message){
        return Error(error.message)
    }

    return Error("Unknown Error")
}

const createRequestInterceptor = () => {

    /*
        -> Set token for closed api's
        -> Convert payload - camelCase to snake_case
    */

    axios.interceptors.request.use(config => {

        let token = getAuthToken()

        // open url list will be excluded
        if (!OPEN_URL_LIST.includes(config.url.replace(BASE_URL, '')) && token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        if (config.url.replace(BASE_URL, '')) {
            config.headers['Content-Type'] = 'application/json';

        }

        return config
    }, error => {
        return Promise.reject(error)
    })
}


const createResponseInterceptor = () => {

    /*
        -> camelize response data
        -> Save user data and token
        -> handle token time out and refresh token in background
    */
    const interceptor = axios.interceptors.response.use(({ config, data, headers }) => {

        if (!config || !data)
            return Promise.reject(Error("Something went wrong"))

        const reqUrl = config.url.replace(BASE_URL, '')

        if (reqUrl === AUTHENTICATE) {
            setAuthToken(headers[KEY_X_AUTH_TOKEN])
            setRefreshToken(headers[KEY_X_REFRESH_TOKEN])
        }

        if (reqUrl === REFRESH_TOKEN) {
            setAuthToken(headers[KEY_X_AUTH_TOKEN])
        }

        return Promise.resolve(data)
    }, async (error) => {

        if(!error.response){
            return Promise.reject(handleServerError(error))
        }

        const originalRequest = error.config

        const reqUrl = error.response.config.url.replace(BASE_URL, '')

        if (error.response.status === 401 && !originalRequest._retry && reqUrl !== REFRESH_TOKEN) {

            originalRequest._retry = true

            const retryOriginalRequest = new Promise((resolve) => {
                refreshSubscribers.push(() => resolve(axios(originalRequest)))
            })

            if (!isRefreshing) {
                isRefreshing = true

                try {

                    const body = {
                        refreshToken: getAuthRefreshToken()
                    }

                    removeAuthToken()

                     await axios
                        .post(
                            `${BASE_URL}${REFRESH_TOKEN}`,
                            body,
                        )
  
                    refreshSubscribers.forEach((subscriber) => subscriber())
                    refreshSubscribers = []

                } catch (refreshError) {
                    clearLocalStorage()
                    window.location = '/'
                }

                isRefreshing = false
            }

            return retryOriginalRequest
        }

        // Return any other error response
        return Promise.reject(handleServerError(error))

    })


}

createRequestInterceptor()
createResponseInterceptor()
