const LSK_STYRA_AUTH_TOKEN = 'styra_auth_token'
const LSK_STYRA_AUTH_REFRESH_TOKEN = 'styra_auth_refresh_token'

export const getAuthToken = () => {
    return localStorage.getItem(LSK_STYRA_AUTH_TOKEN)
}

export const getAuthRefreshToken = () => {
    return localStorage.getItem(LSK_STYRA_AUTH_REFRESH_TOKEN)
}

export const setAuthToken = (token) => {
    localStorage.setItem(LSK_STYRA_AUTH_TOKEN, token)
}

export const setRefreshToken = (token) => {
    localStorage.setItem(LSK_STYRA_AUTH_REFRESH_TOKEN, token)
}

export const removeAuthToken = () => {
    localStorage.removeItem(LSK_STYRA_AUTH_TOKEN)
}

export const clearLocalStorage =()=>{
    localStorage.clear()
}
