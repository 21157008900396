
// react
import React, { useState } from 'react';
import { login } from "./api/AxiosHelper";
import "./vendor/fontawesome-free/css/all.min.css";
import "./vendor/bootstrap.min.css";
import "./css/ruang-admin.min.css";

import logo from "./img/logo/logo2.jpeg"

function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const handleLogin = (e) => {
    if (e.code == 'Enter' || e.type == 'click') {
      setError(null);
      setLoading(true);
      var username1 = username.value;
      var password1 = password.value;

      login(username1, password1)
        .then(data => {
          const { userId, firstName, buRid } = data
          props.history.push({
            pathname: '/map',
            state: { logindetails: `${userId},${firstName},${buRid}` }
          });
        }).catch(e => {
          alert("Invalid user name or password. Please check your credentials.");
        }).finally(() => {
          setLoading(false);
        })

      return
    }
  }


  return (
    <div className='Login-component'>
      <div className="container-login vh-100">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-12 col-md-9">
            <div className="card shadow-sm my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="login-form">
                      <p align='center'>
                        <img src={logo} alt="logo" width="62px" height="62px" />
                        <h1 className="h4 text-gray-900 mb-4">STYRA Geospatial Application</h1>
                      </p>
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Login</h1>
                      </div>
                      <div className="form-group">
                        <input type="email" {...username} autoComplete="new-password" className="form-control" id="exampleInputEmail" aria-describedby="emailHelp"
                          placeholder="Enter Email Address" />
                      </div>
                      <div className="form-group">
                        <input type="password" {...password} autoComplete="new-password" className="form-control" id="exampleInputPassword" placeholder="Password" onKeyDown={handleLogin} />
                      </div>
                      <div className="form-group">
                        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                        <input type="button" className="btn btn-primary btn-block" style={{ backgroundColor: '#336699' }} value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} /><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;
